import React from 'react';
import { mediaMdDown, mediaSmDown } from 'theme/helpers/css';
import CloseIcon from 'Components/Icon/CloseButtonIcon';
import styled from 'styled-components';
import Flex from '../Grid/Flex';
import { colors } from 'constants/colors';

interface IInfoPopoverProps {
  handleCloseBanner: () => void;
  nameForStorage?: string;
  headerText?: string;
  bodyText: string;
}

const StockInfoPopover = styled(Flex).attrs({
  center: true,
  middle: true,
})`
  flex-direction: column;
  position: absolute;
  top: -91px;
  left: 10px;
  gap: 6px;
  padding: 14px 17px 18px 14px;
  z-index: 100;
  width: 212px;
  border-radius: 12px;
  background-color: ${colors.favoritesPurple};
  color: ${colors.white};

  ${mediaSmDown`
    width: 100%;
    top: -75px;
    left: 0px;
  `}
`;

const StockInfoHeader = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  word-break: break-word;
`;

const StockInfoText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  word-break: break-word;
  letter-spacing: -0.22px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  display: block;
  position: absolute;
  color: ${colors.white} !important;
  height: 10px;
  width: 10px;
  cursor: pointer;
  top: 13px;
  right: 13px;

  path {
    stroke: ${colors.white};
  }
`;

const StockInfoArrow = styled.div`
  width: 10px;
  position: absolute;
  top: -10px;
  left: 115px;
  z-index: 95;
  height: 10px;
  background-color: ${colors.favoritesPurple};
  transform: rotate(45deg);

  ${mediaMdDown`
    visibility: hidden;
  `}
`;

const InfoPopover: React.FC<IInfoPopoverProps> = ({ handleCloseBanner, nameForStorage, headerText, bodyText }) => {
  const closeBanner = () => {
    if (nameForStorage) localStorage.setItem(`${nameForStorage}`, 'shown');
    handleCloseBanner();
  };

  return (
    <>
      <StockInfoArrow />
      <StockInfoPopover>
        <StyledCloseIcon onClick={closeBanner} />
        {!!headerText && <StockInfoHeader>{headerText}</StockInfoHeader>}
        <StockInfoText>{bodyText}</StockInfoText>
      </StockInfoPopover>
    </>
  );
};

export default InfoPopover;
