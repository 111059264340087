import styled from 'styled-components';
import Title from 'Components/Typography/Title';
import { colors } from 'constants/colors';
import { mediaMdDown, mediaSmDown } from 'theme/helpers/css';
import { Box, Flex } from '@igooods/ui-kit';
import { getTypographyFontStyle } from '../../theme/selectors';
import { FontFamily, FontSize } from 'theme';
import ExternalLink from 'Components/ExternalLink';
import ThemeButton from 'Components/ThemedButton';

export const CategoryContainer = styled(Box).attrs({ mt: 10 })``;

export const CategoryTitle = styled(Title).attrs({
  level: '2',
  fontFamily: FontFamily.secondFont,
})`
  margin-bottom: 20px;

  ${getTypographyFontStyle(FontSize.xl)}

  ${mediaMdDown`
    ${getTypographyFontStyle(FontSize.l)}
  `}

  ${mediaSmDown`
    margin-left: 16px;
    margin-bottom: 16px;
  `}
`;

export const CategoryTotal = styled.span`
  color: ${colors.pinkishGrey};
  font-size: 20px;
  line-height: 28px;

  ${mediaMdDown`
    ${getTypographyFontStyle(FontSize.s)}
  `}
`;

export const PharmacyMock = styled(Flex).attrs({ py: 2 })``;

export const InvoiceLink = styled(ExternalLink)`
  text-decoration: none;
  color: #8c8c95;
`;

export const StyledButton = styled(ThemeButton)`
  padding: 0;
  height: 22px;
`;
