import React, { useEffect, useMemo, useState } from 'react';
import Row from 'Components/Grid/Row';
import Col from 'Components/Grid/Col';
import { setNotificationAction } from 'Modules/Notifications/actions';
import { EMPTY_STRING, RUB } from 'constants/constants';
import { EnumButtonSize, EnumFromName, EnumGtagEvents, EnumNotification, EnumTheme } from 'constants/enums';
import { IAddToOrder } from './index';
import { IOrder } from 'Modules/Cart/types';
import OrdersHttp from 'Modules/Cart/http';
import { shortRelativeDate, timePeriod } from 'utils/date';
import { floatToNumberThousandSeparator, truncateString } from 'utils/helpers';
import { EnumProductType, IProduct } from 'Modules/Products/types';
import addProductInOrderAnalitic from 'utils/analitic/addProductInOrderAnalitic';
import {
  ActionsBox,
  MobileModalTitle,
  StyledDropdown,
  StyledQuantityControls,
  StyledTextarea,
  SubmitButton,
  TotalDesktop,
  TotalMobile,
  ModalStyled,
  ProductListCardShortStyled,
} from './styled';
import {
  ADD_TO_ORDER_BUTTON_TEST_ID,
  ADD_TO_ORDER_DROPDOWN_TEST_ID,
  ADD_TO_ORDER_INPUT_TEST_ID,
  PRODUCT_ADD_TO_ORDER_MODAL_TEST_ID,
} from '../constants';
import Text from 'Components/Typography/Text';
import { colors } from 'constants/colors';
import { Box } from '@igooods/ui-kit';
import { getOrderProductsAction } from 'Modules/OrdersHistory/actions';
import { ENERGY_DRINK_CATEGORY_ID } from 'Components/Product/constants';
import { useThunkDispatch } from 'reduxStore/types';
import OrderActions, { setActiveOrders } from 'Modules/Cart/actions';
import { assoc, map, propEq, when } from 'ramda';
import { setGtagUserProperties, useGtagUserProperties } from 'utils/gtag';
import { TagManagerAddProductToCart } from 'utils/tagManager';
import { IAnalyticsParams } from 'utils/tagManager/types';
import analyticsManager from 'utils/tagManager/manager';

interface IAddToOrderModal extends IAddToOrder {
  closeModal: () => void;
  orders: IOrder[];
  productForAnalitic: IProduct;
  from: EnumFromName;
  from_product_page: boolean;
  analyticsParams?: IAnalyticsParams;
  onAddToOrder?: () => void;
}

const MAX_WEIGHT_ORDER = 80000;

export const OVERWEIGHT_NOTIFY = 'Товар не добавлен. Максимальный вес заказа - 80 кг.';

const formattedOrderTitle = (number: number, date: string) =>
  `В заказ № ${number} на ${timePeriod(date)}, ${shortRelativeDate(date)}`;

const AddToOrderModal: React.FC<IAddToOrderModal> = ({
  id,
  price,
  item_weight,
  type,
  closeModal,
  orders,
  productForAnalitic,
  from,
  price_per_kg,
  category_id,
  analyticsParams,
  onAddToOrder,
}) => {
  const dispatch = useThunkDispatch();
  const [newAmount, setNewAmount] = useState(1);
  const [newWeight, setNewWeight] = useState(item_weight);
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState(EMPTY_STRING);
  const [selectedOrderItem, setSelectOrderItem] = useState(0);
  const isQuantityWeight = type === EnumProductType.weight;
  const setCommonUserProperties = useGtagUserProperties();

  useEffect(() => {
    setNewWeight(item_weight * newAmount);
  }, [item_weight, newAmount]);

  useEffect(() => {
    return () => {
      setCommonUserProperties();
    };
  }, [setCommonUserProperties]);

  const activeOrders = useMemo(() => orders.map(order => formattedOrderTitle(order.id, order.delivery_date.date)), [
    orders,
  ]);
  const handleChangeComment = (value: string) => setComment(value);
  const handleSelectOrder = (i: number) => setSelectOrderItem(i);
  const currentOrderID = orders[selectedOrderItem].id;
  const currentOrder = orders[selectedOrderItem];
  const currentOrderTotalPrice = Math.ceil(
    orders[selectedOrderItem].total_price +
      (type === EnumProductType.weight ? (price_per_kg * newWeight) / 1000 : price * newAmount)
  );
  const currentOrderWeight = orders[selectedOrderItem].weight;

  const addToOrder = async () => {
    try {
      setIsLoading(true);

      //для первого энергетика (category_id === 109) должны показывать модалку про паспорт
      if (category_id === ENERGY_DRINK_CATEGORY_ID) {
        const orderProducts = await dispatch(getOrderProductsAction(currentOrderID));
        dispatch(OrderActions.checkIsNeedPassport(category_id, orderProducts));
      }

      await OrdersHttp.addProductToOrder({
        orderId: currentOrderID,
        product_id: id,
        amount: newAmount,
        comment: comment,
        ...(type === EnumProductType.weight && { weight: item_weight * newAmount }),
      });

      const shopGroup = currentOrder?.shop?.group_en || currentOrder?.shop?.group;

      setGtagUserProperties({
        order_id: truncateString(currentOrder.id, 36),
        ...(currentOrder?.shop && { shop_id: truncateString(currentOrder?.shop?.id, 36) }),
        ...(shopGroup && { shop_group: truncateString(shopGroup, 36) }),
      });
      TagManagerAddProductToCart(
        productForAnalitic,
        { amountOfChange: newAmount },
        from,
        analyticsParams,
        currentOrder.id,
        undefined,
        true
      );

      comment && analyticsManager.gtag(EnumGtagEvents.WRITE_PRODUCT_COMMENT, { from: EnumFromName.ORDER_CATALOG });

      const findById = propEq('id', currentOrderID);
      const setUpdatedTotalPrice = assoc('total_price', currentOrderTotalPrice);
      const setTotalPrice = map(when(findById, setUpdatedTotalPrice));
      const newOrders = setTotalPrice(orders);
      dispatch(setActiveOrders(newOrders));

      dispatch(setNotificationAction(`Товар успешно добавлен в заказ № ${currentOrderID}`, EnumNotification.success));
      addProductInOrderAnalitic(productForAnalitic, orders[selectedOrderItem]);
    } catch (error) {
      if (error?.response?.data?.error) {
        dispatch(setNotificationAction(error.response.data.error, EnumNotification.danger));
      }
    } finally {
      closeModal();
    }
  };

  const submitAddToOrder = () => {
    const totalOrderWeight = currentOrderWeight + newAmount * item_weight;

    if (totalOrderWeight <= MAX_WEIGHT_ORDER) {
      addToOrder();
      onAddToOrder && onAddToOrder();
    } else {
      dispatch(setNotificationAction(OVERWEIGHT_NOTIFY, EnumNotification.danger));
    }
  };

  const handleChange = (newAmount: number) => newAmount > 0 && setNewAmount(newAmount);

  const totalText = (
    <>
      <Text color={colors.dark300}>Стоимость заказа с доставкой − </Text>{' '}
      <Text fontWeight="600" color={colors.dark300}>
        {floatToNumberThousandSeparator(currentOrderTotalPrice)} {RUB}
      </Text>
    </>
  );

  return (
    <ModalStyled data-testid={PRODUCT_ADD_TO_ORDER_MODAL_TEST_ID}>
      <MobileModalTitle level="3">Добавить товар в заказ</MobileModalTitle>
      {/* Надо будет разобраться, почему здесь в КТ прокидывается именно объект товара для аналитики */}
      <ProductListCardShortStyled
        product={{ ...productForAnalitic, amount: 1, weight: productForAnalitic.item_weight }}
      />
      <ActionsBox>
        <Row>
          <Col xs={12} md={6} lg={6} grow>
            <TotalMobile>{totalText}</TotalMobile>
            <Box>
              <StyledQuantityControls
                showWeight={isQuantityWeight}
                weight={item_weight}
                quantity={newAmount}
                onChange={handleChange}
                isKomus
              />
            </Box>
          </Col>
          <Col xs={12} md={6} lg={6} grow>
            <SubmitButton
              inDesktop
              onClick={submitAddToOrder}
              fluid
              testId={ADD_TO_ORDER_BUTTON_TEST_ID}
              isLoading={isLoading}
              use={EnumTheme.roundRed}
            >
              Добавить
            </SubmitButton>
            <SubmitButton
              inMobile
              size="large"
              onClick={submitAddToOrder}
              fluid
              testId={ADD_TO_ORDER_BUTTON_TEST_ID}
              isLoading={isLoading}
              use={EnumTheme.roundRed}
            >
              Добавить
            </SubmitButton>
          </Col>
        </Row>
      </ActionsBox>
      <StyledDropdown
        list={activeOrders}
        selectedItem={selectedOrderItem}
        onSelectDropdownItem={handleSelectOrder}
        testIdButton={ADD_TO_ORDER_DROPDOWN_TEST_ID}
        size={EnumButtonSize.large}
        use={EnumTheme.quaternary}
        withChevron
      />
      <StyledTextarea
        testIdTextarea={ADD_TO_ORDER_INPUT_TEST_ID}
        onChange={handleChangeComment}
        placeholder="Примечание для закупщика. Например, возможный аналог или желаемый внешний вид"
        maxLength={150}
      />
      <TotalDesktop>{totalText}</TotalDesktop>
    </ModalStyled>
  );
};

export default AddToOrderModal;
