import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  calcShowPrice,
  floatThousandSeparator,
  numberThousandSeparator,
  floatToNumberThousandSeparator,
  formattedWeight,
  numberToFloatString,
} from 'utils/helpers';
import Promocodes from './Promocodes/Promocodes';
import { getCartState, getNotEnoughProductsPrice } from 'Modules/Cart/selectors';
import Text from 'Components/Typography/Text';
import { Box, Flex } from '@igooods/ui-kit';
import { RUB } from 'constants/constants';
import OrderActions from 'Modules/Cart/actions';
import { getCurrentUser } from 'Modules/AAA/selectors';
import Tooltip from 'Components/Tooltip';
import { EnumButtonSize, EnumTheme } from 'constants/enums';
import { colors } from 'constants/colors';
import { FontFamily, FontSize, FontWeight } from 'theme';
import Condition from './Condition';
import { setNotificationAction } from 'Modules/Notifications/actions';
import { EnumNotification } from 'constants/enums';
import { INVITE_PROMOCODE } from 'App';
import Promocode from '../../Containers/Modals/Discount/promocode';
import { getCartProductsTotalPrice } from 'Modules/Cart/selectors';
import AdditionalWeightContent from './AdditionalWeightContent';
import { ICheck, ICollapsable, INotify } from './Types/types';
import styled from 'styled-components';
import { HEADER_HEIGHT } from '../Header/HeaderContainer';
import { mediaMdDown, mediaSmDown } from '../../theme/helpers/css';
import { addStyleIfPropTruly } from '../../utils/styledComponents';
import ThemeButton from 'Components/ThemedButton';
import { IS_KOMUS } from '../../../config/config';

export const CHECKOUT_ORDER_TESTID = 'CHECKOUT_ORDER_TESTID';
export const CART_REMOVE_PROMOCODE_TEST_ID = 'CART_REMOVE_PROMOCODE_TEST_ID';
export const CHECK_PROMOCODE_TEXT_TEST_ID = 'CHECK_PROMOCODE_TEXT_TEST_ID';
export const CHECK_CONTAINER_TEST_ID = 'CHECK_CONTAINER_TEST_ID';
export const CHECK_FEWER_PLASTIC_BAGS_TOGGLE_TEST_ID = 'CHECK_FEWER_PLASTIC_BAGS_TOGGLE_TEST_ID';
export const CHECK_CONTAINER_ELEMENTS_TEST_ID = 'CHECK_CONTAINER_ELEMENTS_TEST_ID';
export const WEIGHT_CART_VALUE_TEST_ID = 'WEIGHT_CART_VALUE_TEST_ID';
export const CART_TOTAL_PRICE_WITH_DELIVERY_VALUE_TEST_ID = 'CART_TOTAL_PRICE_WITH_DELIVERY_VALUE_TEST_ID';
export const CHECKOUT_TOTAL_SALE_COUNT_TEST_ID = 'CHECKOUT_TOTAL_SALE_COUNT_TEST_ID';

export const CheckContainer = styled(Box).attrs({
  pt: 2,
  pb: { xs: 1, sm: 1.5, md: 2 },
  px: { xs: 5, sm: 13, md: 5 },
  mt: true,
  mb: { xs: 16.5, sm: 0 },
})`
  background-color: ${colors.white};
  position: fixed;
  top: calc(${HEADER_HEIGHT}px + 56px);
  right: 17px;
  width: 270px;
  z-index: 2;

  ${mediaMdDown`
    position: static;
    width: 100%;
  `}
`;

export const ConditionsDesktop = styled(Box).attrs({ hideBefore: 'sm' })`
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
`;

export const Content = styled.div<ICollapsable>``;

export const InfoRow = styled(Flex).attrs({ my: 2 })`
  position: relative;
  align-items: center;
  user-select: none;
`;

export const DiscountRow = styled(Flex).attrs({ my: 2 })`
  position: relative;
  align-items: center;
  user-select: none;
  margin-top: 4px;

  ${mediaSmDown`
    margin-top: 0;
  `}
`;

export const ItemHelperRow = styled(Flex).attrs({ my: 1.5 })`
  position: relative;
  align-items: center;
  user-select: none;
`;

export const PriceRow = styled(Flex).attrs({ mb: 2.5 })`
  position: relative;
  align-items: center;
`;

export const Key = styled(Text)`
  margin-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${FontFamily.mainFont};

  ${mediaSmDown`
    font-size: 14px;
    line-height: 22px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}
`;

export const InfoKey = styled(Text)`
  margin-right: 16px;

  ${mediaSmDown`
    font-size: 14px;
    line-height: 22px;
    position: relative;
  `}
`;

export const ItemHelperKey = styled(Text)`
  margin-right: 16px;
  color: ${colors.dark200};
`;

export const Value = styled(Text)`
  margin-left: auto;
  font-family: ${FontFamily.mainFont};

  ${mediaSmDown`
    font-size: 14px;
    line-height: 22px;
  `}
`;

export const InfoValue = styled(Text)`
  margin-left: auto;

  ${mediaSmDown`
    font-size: 14px;
    line-height: 22px;
  `}
`;

export const PriceValue = styled(Text).attrs({
  size: FontSize.xl,
  fontWeight: FontWeight.medium,
  fontFamily: FontFamily.secondFont,
})`
  line-height: 30px;
  ${mediaSmDown`
    font-size: 24px;
  `}
`;

export const PriceSign = styled(Text).attrs({
  size: FontSize.xl,
  fontWeight: FontWeight.semiBold,
})`
  margin-left: 6px;

  ${mediaSmDown`
    margin-right: 4px;
    font-size: 24px;
    line-height: 30px;
  `}
`;

export const RemoveButton = styled(ThemeButton).attrs({
  use: EnumTheme.link,
  size: EnumButtonSize.small,
  icon: true,
  circle: true,
})`
  height: 22px;
  width: 22px;
  position: absolute;
  right: -22px;
  top: -1px;
  ${mediaSmDown`
    right: -19px;
    top: 0;
  `}
`;

export const Footer = styled(Box).attrs({ hideBefore: 'sm', py: 1.5 })`
  width: 100%;
`;

export const Notify = styled(Flex).attrs({ middle: true, px: 6, mb: 4 })<INotify>`
  height: 50px;
  background-color: ${colors.grederlevy};
  ${addStyleIfPropTruly('alert', `background-color: ${colors.whiteLily};`)}
`;

export const OldPrice = styled.span`
  text-decoration: line-through;
  margin-right: 9px;
  color: ${colors.warmGrey};
`;

export const SubmitButton = styled(ThemeButton).attrs({
  size: EnumButtonSize.normal,
  testId: CHECKOUT_ORDER_TESTID,
  fluid: true,
  use: EnumTheme.roundRed,
})<{
  isHaveSoldoutProducts?: boolean;
}>`
  line-height: 18px;
  font-size: 16px;
  border: none !important;
  ${({ isHaveSoldoutProducts }) => isHaveSoldoutProducts && `opacity: 0.8 !important;`}
  &:hover,
  &:focus {
    button {
      border: none !important;
    }
  }
  &:disabled {
    background-color: ${colors.primaryAction};
    opacity: 0.5;
    color: ${colors.white};
  }
`;

export const WeightTooltip = styled(Tooltip)`
  div {
    background-color: ${colors.blackRussian};
    border-radius: 12px;
    font-size: 14px;
  }
`;

export const ProgressBar = styled.div`
  width: 64px;
  height: 6px;
  border-radius: 100px;
  background-color: ${colors.ghostWhite};
`;

export const CurrentProgressBar = styled.div<{
  barWidth?: number;
}>`
  ${({ barWidth }) => barWidth && `width: ${barWidth}%`};
  height: 100%;
  border-radius: 100px;
  background-color: ${colors.primaryMain};
  opacity: 0.7;
`;

export const StyledPoints = styled.span`
  cursor: pointer;
  color: ${colors.primaryMain};
`;

export const EntityTooltip = styled.div`
  font-size: 12px;
  line-height: 16px;
  margin-top: 12px;
`;

export const EntityTooltipLink = styled.span`
  color: ${colors.primaryMain};
  cursor: pointer;
`;

const PROMOCODE_ERROR_MESSAGE = 'Недействительный промокод';

export const Price: React.FC = ({ children }) => <Box pb={{ xs: 5, sm: 5, lg: 4 }}>{children}</Box>;

const Check: React.FC<ICheck> = ({
  isCheckoutChild = false,
  isReadyToCheckout,
  onCheckoutOrder,
  conditions = false,
  isLoading = false,
  isHaveSoldoutProducts,
}) => {
  const dispatch = useDispatch();
  const { as_entity } = useSelector(getCurrentUser);
  const currentOrder = useSelector(getCartState);
  const productsTotalPrice = useSelector(getCartProductsTotalPrice);
  const notEnoughProductsPrice = useSelector(getNotEnoughProductsPrice);
  const {
    total_price,
    service_info,
    user_points,
    promocode,
    discounts,
    weight,
    points = 0,
    fewer_plastic_bags,
    total_sale_discount,
  } = currentOrder;
  const [isFewerBags, setIsFewerBags] = useState(fewer_plastic_bags);
  const [totalPointsToSpend, setTotalPointsToSpend] = useState(points);
  const isNotEnoughProductPrice = notEnoughProductsPrice > 0;
  const assembly = service_info.find(part => part.type === 'assembly');
  const packaging = service_info.find(part => part.type === 'packaging');
  const threshold = assembly?.threshold;
  const diffForFreeAssembly =
    packaging?.price && threshold && threshold - Math.floor(productsTotalPrice) - packaging.price;
  const progressBarWidth = diffForFreeAssembly && threshold && 100 - diffForFreeAssembly / (threshold / 100);

  const submitButtonText = isNotEnoughProductPrice
    ? `Добавьте еще на ${floatToNumberThousandSeparator(notEnoughProductsPrice)} ${RUB}`
    : isHaveSoldoutProducts
    ? 'Удалите раскупленное'
    : 'К оформлению заказа';

  useEffect(() => {
    const initialPoints =
      user_points && +numberThousandSeparator(total_price, true) > user_points
        ? user_points
        : Number(numberThousandSeparator(total_price, true));
    if (!totalPointsToSpend) {
      setTotalPointsToSpend(initialPoints);
    }
  }, [totalPointsToSpend, total_price, user_points]);

  const shouldDisableFewerBags = Boolean(
    currentOrder.pendingProducts.add.length || currentOrder.pendingProducts.remove.length
  );

  useEffect(() => {
    const setFeaverBags = async () => {
      if (!isFewerBags && !shouldDisableFewerBags) {
        setIsFewerBags(true);
        await dispatch(OrderActions.toogleFewerPlasticBags(true));
      }
    };
    setFeaverBags();
  }, [dispatch, isFewerBags, shouldDisableFewerBags]);

  const discountWithPromo = discounts?.find(discount => discount.type === 'coupon')?.discount;

  useEffect(() => {
    const invitePromocode = localStorage.getItem(INVITE_PROMOCODE);
    try {
      if (invitePromocode && !promocode && !as_entity) {
        dispatch(OrderActions.addPromocode(invitePromocode));
      }
    } catch (e) {
      dispatch(setNotificationAction(PROMOCODE_ERROR_MESSAGE, EnumNotification.danger));
      localStorage.removeItem(INVITE_PROMOCODE);
    }
  }, [as_entity, dispatch, promocode]);

  const filteredServiceInfo = [service_info[1], service_info[0], service_info?.[3], service_info[2]];

  const calculateDiscount = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const assemblyDiscount = !!assembly?.real_price ? assembly.real_price - assembly.price : 0;
    return total_sale_discount ? total_sale_discount + assemblyDiscount + points : assemblyDiscount + points;
  };

  const convertItemName = (name: string | undefined) => {
    if (name) {
      return name === 'Работа сервиса' ? 'Доставка' : name;
    }

    return;
  };

  const summaryDiscount = calculateDiscount();

  return (
    <CheckContainer data-testid={CHECK_CONTAINER_TEST_ID}>
      <Content>
        {filteredServiceInfo.map(
          item =>
            Boolean(item) && (
              <div key={convertItemName(item.name)}>
                <InfoRow>
                  <Key size={FontSize.m} color={colors.dark300}>
                    {convertItemName(item.name)}
                    {item.type === 'overweight' && <WeightTooltip>{AdditionalWeightContent()}</WeightTooltip>}
                  </Key>
                  <Value size={FontSize.m} color={colors.dark300} data-testid={CHECK_CONTAINER_ELEMENTS_TEST_ID}>
                    {item?.real_price && item.type !== 'packaging' && (
                      <OldPrice>{floatThousandSeparator(item?.real_price)}</OldPrice>
                    )}
                    {calcShowPrice(item.price)}
                  </Value>
                </InfoRow>
                {item.type === 'assembly' && !!diffForFreeAssembly && !!threshold && diffForFreeAssembly > 0 && (
                  <ItemHelperRow>
                    <ItemHelperKey size={FontSize.s}>
                      До бесплатной {diffForFreeAssembly} {RUB}
                    </ItemHelperKey>
                    <Value size={FontSize.s}>
                      <ProgressBar>
                        <CurrentProgressBar barWidth={progressBarWidth} />
                      </ProgressBar>
                    </Value>
                  </ItemHelperRow>
                )}
              </div>
            )
        )}
        {!IS_KOMUS && user_points !== undefined && as_entity && (
          <InfoRow>
            <Key size={FontSize.m} color={colors.dark300}>
              Баланс
            </Key>
            <Value size={FontSize.m} color={colors.dark300}>
              {floatThousandSeparator(user_points)} {RUB}
            </Value>
          </InfoRow>
        )}
      </Content>
      <>
        {!IS_KOMUS && (
          <DiscountRow>
            {/*{promocode.current_discount && }*/}
            <Key size={FontSize.m} color={colors.dark300}>
              Промокод
            </Key>
            {!!discountWithPromo && (
              <Value size={FontSize.m} color={colors.primaryAction} data-testid={'CHECKOUT_DISCOUNT'}>
                -{discountWithPromo} {RUB}
              </Value>
            )}
          </DiscountRow>
        )}

        {/*<InfoRow>*/}
        {/*  <Key>Промокод</Key>*/}
        {/*</InfoRow>*/}
        {!IS_KOMUS && (
          <InfoRow>
            <Promocode />
          </InfoRow>
        )}
      </>
      {!IS_KOMUS && <Promocodes activePromo={promocode} />}

      <Price>
        {Boolean(summaryDiscount) && (
          <DiscountRow>
            <Key size={FontSize.s} color={colors.dark200}>
              Cкидка
            </Key>
            <Value size={FontSize.s} color={colors.primaryMain} data-testid={CHECKOUT_TOTAL_SALE_COUNT_TEST_ID}>
              -{numberToFloatString(summaryDiscount)} {RUB}
            </Value>
          </DiscountRow>
        )}
        <PriceRow>
          <Key size={FontSize.s} color={colors.dark300}>
            Итого
          </Key>
          <Value data-testid={WEIGHT_CART_VALUE_TEST_ID} size={FontSize.s}>
            <PriceValue data-testid={CART_TOTAL_PRICE_WITH_DELIVERY_VALUE_TEST_ID}>
              {numberThousandSeparator(total_price) || 0}
              <PriceSign>{RUB}</PriceSign>
            </PriceValue>
          </Value>
        </PriceRow>
        <InfoRow>
          <InfoKey size={FontSize.s} color={colors.dark200}>
            Вес
          </InfoKey>
          <InfoValue data-testid={WEIGHT_CART_VALUE_TEST_ID} color={colors.dark200} size={FontSize.s}>
            {formattedWeight(weight)} кг
          </InfoValue>
        </InfoRow>
      </Price>
      <Footer>
        <SubmitButton
          disabled={!isReadyToCheckout && !isHaveSoldoutProducts}
          isLoading={isLoading}
          onClick={!isNotEnoughProductPrice && onCheckoutOrder}
          isHaveSoldoutProducts={isHaveSoldoutProducts}
        >
          {isCheckoutChild ? submitButtonText : 'Оформить'}
        </SubmitButton>
      </Footer>
      {conditions && (
        <ConditionsDesktop>
          <Condition />
        </ConditionsDesktop>
      )}
    </CheckContainer>
  );
};

export default Check;
