import styled from 'styled-components';
import { Flex, Box } from '@igooods/ui-kit';
import { mediaSmDown } from '../../../theme/helpers/css';
import { colors } from '../../../constants/colors';

const POPUP_WIDTH_DESKTOP = 456;

export const PopupBox = styled(Box)`
  max-height: 500px;
  overflow-y: scroll;
  scroll-margin: 5px 20px 5px 0;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    margin-right: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.paleBlackRussian};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const Container = styled(Flex).attrs({
  column: true,
})`
  padding: 48px 40px 44px;
  box-sizing: border-box;
  width: ${POPUP_WIDTH_DESKTOP}px;
  justify-content: center;
  align-items: flex-start;
  ${mediaSmDown`
    width: 100%;
    padding-bottom: 0;
  `}
`;

export const CategoriesListContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: ${colors.paleBlackRussianWeak};
  font-size: 14px;
  font-weight: 200;
  line-height: 22px;
  letter-spacing: -0.2px;
  gap: 10px;
`;

export const PromoTitle = styled.div`
  margin-bottom: 22px;
  color: ${colors.blackRussian};
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.3px;
`;

export const PromoDescription = styled.div`
  margin-bottom: 12px;
  color: ${colors.blackRussian};
  font-size: 14px;
  font-weight: 200;
  line-height: 22px;
  letter-spacing: -0.2px;
`;

export const PromoConditions = styled.div`
  margin-bottom: 8px;
  color: ${colors.paleBlackRussianWeak};
  font-size: 14px;
  font-weight: 200;
  line-height: 22px;
  letter-spacing: -0.2px;
`;

export const Category = styled(Flex)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

export const SquareDot = styled.span`
  width: 2px;
  height: 2px;
  background-color: ${colors.paleBlackRussianWeak};
`;
