import styled from 'styled-components';
import Text from 'Components/Typography/Text';
import { mediaCustomUp, mediaSmDown, mediaSmUp, mediaMdDown } from 'theme/helpers/css';
import { hideAfterSm, hideBeforeSm } from 'theme/helpers';
import Textarea from 'Components/Textarea';
import { getTypographyFontStyle } from 'theme/selectors';
import { defaultTheme, FontSize } from 'theme';
import Title from 'Components/Typography/Title';
import Dropdown from 'Components/DropdownSelect';
import { Box, QuantityControls } from '@igooods/ui-kit';
import ThemeButton from 'Components/ThemedButton';
import { colors } from 'constants/colors';
import { Modal } from '../../../Containers/Modals/styled';
import ProductListCardShort from '../ListCardShort';

export const Name = styled(Text)`
  margin: 4px 0;

  ${mediaSmDown`
    margin-top: 0;
    margin-bottom: 4px;
  `}
`;

export const Total = styled(Text).attrs({
  center: true,
})`
  display: block;
`;

export const TotalDesktop = styled(Total)`
  margin-top: 33px;
  ${hideBeforeSm}
`;

export const TotalMobile = styled(Total)`
  margin-bottom: 16px;
  ${hideAfterSm}
`;

export const StyledTextarea = styled(Textarea)`
  -webkit-appearance: none;
  height: 110px;
  ${getTypographyFontStyle(FontSize.s)};
`;

export const MobileModalTitle = styled(Title)`
  margin-bottom: 16px;
  ${hideAfterSm}
`;

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;

  ${mediaSmDown`
    margin-bottom: 8px;
  `}
`;

export const ActionsBox = styled(Box).attrs({ my: { xs: 0, sm: 10 } })`
  ${mediaSmDown`
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100vw;
    padding: 16px;
    background-color: ${colors.white};
  `}
`;

export const SubmitButton = styled(ThemeButton)`
  max-height: 50px;
  ${mediaSmDown`
    margin-top: 8px;
  `}
`;

export const Weight = styled(Text)`
  ${mediaSmDown`
    margin-bottom: 10px;
  `}
`;

export const StyledQuantityControls = styled(QuantityControls)`
  ${mediaCustomUp(defaultTheme.breakpoints['sm'])`
    height: 50px;
  `}
`;

export const ModalStyled = styled(Modal)`
  ${mediaSmUp`
    width: 480px;
  `}
`;

export const ProductListCardShortStyled = styled(ProductListCardShort)`
  & div {
    font-size: 14px;
    font-weight: 400;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;

    &:not(:first-child)[class*='ListQuantity'] {
      margin-right: 5px;
      display: block;
    }

    ${mediaMdDown`
       flex-direction: column;
       justify-content: initial;
       align-items: flex-start;
       margin: 0;
       
      &:not(:first-child) {
        height: 18px;
        margin-right: 5px;
      } 
    `}

    ${mediaSmDown`
       flex-direction: row;
       justify-content: initial;
       align-items: flex-start;
       margin: 0;
       
       & div[class*='ControlBox']{
        padding: 0px;
       }
       
       & div[class*='FullPrice']{
        gap: 0px;
       }
       
       &:not(:first-child)[class*='ListQuantity'] {
        margin-right: 5px;
        display: inline-block;
      }
    `}
  }

  ${mediaSmDown`
    margin: 30px 0;
  `}
`;
