import { FontSize } from 'theme';
import React, { memo, ReactNode } from 'react';
import { mediaSmDown, mediaSmUp } from 'theme/helpers/css';

import Icon from 'Components/Typography/Icon';
import Text from 'Components/Typography/Text';
import { TooltipTheme } from 'constants/enums';
import { colors } from 'constants/colors';
import { getTypographyFontStyle } from 'theme/selectors';
import styled from 'styled-components';
import usePositionDetection from 'utils/hooks/usePositionDetection';
import QuestionIcon from '../Icon/QuestionIcon';
import AttentionIcon from '../Icon/AttentionIcon';

interface ITooltip {
  text?: string;
  theme?: TooltipTheme;
  className?: string;
  tooltipTestId?: string;
  testId?: string;
  children?: ReactNode;
}

interface IContainer {
  $positionX: string;
  $positionY: string;
}

export const TOOLTIP_CONTAINER_TEST_ID = 'TOOLTIP_CONTAINER_TEST_ID';
export const TOOLTIP_CONTENT_TEST_ID = 'TOOLTIP_CONTENT_TEST_ID';
export const TOOLTIP_TOGGLE_TEST_ID = 'TOOLTIP_TOGGLE_TEST_ID';

const Container = styled.div<IContainer>`
  width: max-content;
  box-sizing: content-box;
  max-width: 400px;
  display: block;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  padding: 6px 12px;
  background-color: ${colors.greyishBrown};
  border-radius: 4px;
  color: ${colors.white};
  ${({ $positionX }) => $positionX}: -5px;
  ${({ $positionY }) => $positionY}: calc(100% + 5px);
  animation: fade-out 0.3s cubic-bezier(0.4, 0.1, 0.1, 0.9) forwards;
  will-change: transform, opacity;
  z-index: 2;

  ${mediaSmDown`
    max-width: 164px;
    left: 30px;
    right: auto;
  `}
`;

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  margin: 0 4px;

  &:hover ${Container} {
    opacity: 1;
    visibility: visible;
    animation: fade-in 0.3s cubic-bezier(0.4, 0.1, 0.1, 0.9) forwards;
    will-change: transform, opacity, visibility;
  }

  ${mediaSmDown`
    position: static;
  `}
`;

const TooltipIcon = styled(Icon)`
  display: inline-block;
  padding-left: 2px;
  vertical-align: middle;
`;

export const Content = styled(Text)`
  white-space: normal;
  ${mediaSmUp`
    display: table;
    width: max-content;
    max-width: 260px;
  `}
  ${getTypographyFontStyle(FontSize.xs)};
`;

const Tooltip: React.FC<ITooltip> = ({
  text,
  theme = TooltipTheme.question,
  className,
  tooltipTestId = TOOLTIP_CONTENT_TEST_ID,
  testId = TOOLTIP_CONTAINER_TEST_ID,
  children,
}: ITooltip) => {
  const { positionX, positionY, elementRef, wrapperRef } = usePositionDetection({
    eventListener: 'mouseover',
    xPadding: 20,
    yPadding: 100,
  });

  return (
    <Wrapper data-testid={testId} ref={wrapperRef} className={className}>
      {
        {
          [TooltipTheme.exclamation]: (
            <TooltipIcon data-testid={TOOLTIP_TOGGLE_TEST_ID}>
              <AttentionIcon color={colors.reddishPink} fill={colors.reddishPink} />
            </TooltipIcon>
          ),
          [TooltipTheme.question]: (
            <TooltipIcon data-testid={TOOLTIP_TOGGLE_TEST_ID}>
              <QuestionIcon color={colors.pinkishGrey} fill={colors.pinkishGrey} />
            </TooltipIcon>
          ),
        }[theme]
      }
      <Container data-testid={tooltipTestId} ref={elementRef} $positionX={positionX} $positionY={positionY}>
        <Content>{children ?? text}</Content>
      </Container>
    </Wrapper>
  );
};

export default memo(Tooltip);
