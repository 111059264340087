import { colors } from 'constants/colors';
import { defaultTheme, FontFamily } from 'theme';
import { buttonThemeStyles } from '../ThemedButton/themes';
import { FontWeight, FontSize } from 'theme';

/* Старые темы для AddToOrderModal */
export const oldQuantityThemeStyles = {
  bgColor: colors.grederlevyLightTwo,
  color: colors.darkBlueGreen,
  borderColor: colors.darkBlueGreen,
  hover: {
    bgColor: colors.darkBlueGreen,
    color: colors.white,
  },
  borderRadius: 12,
  fontSize: FontSize.l,
  fontWeight: FontWeight.semiBold,
};

export const quantityThemeStyles = {
  fontSize: FontSize.xs,
  fontWeight: FontWeight.semiBold,
  fontFamily: FontFamily.secondFont,
};

export const quantityThemeStylesWithLimit = {
  fontSize: FontSize.xs,
  fontWeight: FontWeight.semiBold,
  fontFamily: FontFamily.secondFont,
  color: colors.darkPink,
  bgColor: colors.almostWhite,
  borderColor: colors.greyishTwo,
  hover: {
    bgColor: colors.almostWhite,
    borderColor: colors.greyishTwo,
  },
};

export const productThemeStyles = {
  typography: {
    ...defaultTheme.typography,
    [FontSize.xs]: { fontSize: '11px', lineHeight: '18px' },
    [FontSize.s]: { fontSize: '14px', lineHeight: '18px' },
    [FontSize.l]: { fontSize: '18px', lineHeight: '26px' },
    [FontSize.xl]: { fontSize: '22px', lineHeight: '30px' },
  },
  typographyMobile: {
    ...defaultTheme.typographyMobile,
    [FontSize.xs]: { fontSize: '10px', lineHeight: '12px' },
    [FontSize.s]: { fontSize: '13px', lineHeight: '16px' },
    [FontSize.l]: { fontSize: '14px', lineHeight: '16px' },
    [FontSize.xl]: { fontSize: '22px', lineHeight: '30px' },
  },
  borderRadius: 0,
};

export const themeCardByWL = {
  ...defaultTheme,

  button: buttonThemeStyles.main,
  ...productThemeStyles,
  /* Временно переписываем стили вовода количества только для сетки */
  quantity: quantityThemeStyles,
};

export const themeCardByWLWithLimit = {
  ...defaultTheme,
  ...productThemeStyles,
  quantity: quantityThemeStylesWithLimit,
};

export const themeListCardByWL = {
  ...defaultTheme,
  typography: {
    ...defaultTheme.typography,
    300: { fontSize: '14px', lineHeight: '18px' },
    400: { fontSize: '16px', lineHeight: '18px' },
    500: { fontSize: '18px', lineHeight: '26px' },
    600: { fontSize: '22px', lineHeight: '26px' },
  },
  typographyMobile: {
    ...defaultTheme.typographyMobile,
    200: { fontSize: '12px', lineHeight: '16px' },
    300: { fontSize: '12px', lineHeight: '16px' },
    500: { fontSize: '14px', lineHeight: '16px' },
    600: { fontSize: '18px', lineHeight: '24px' },
  },
  button: { ...buttonThemeStyles.main, height: { small: 32, normal: 40, large: 50 } },
  ...productThemeStyles,
};
