import { Dispatch, ThunkAction } from '../../reduxStore/types';
import { IProductFavoriteBase, IProductsSet, IProductsSetRequest, EnumProductTypes } from './types';

import { AnyAction } from 'redux';
import CategoriesActions from 'Modules/Categories/actions';
import { EnumFromName } from 'constants/enums';
import { ICategoryChild } from '../Categories/types';
import { IProduct } from 'Modules/Products/types';
import ProductHttp from 'Modules/Products/http';
import ProductsHttp from '../Products/http';
import { getCurrentFavoriteProductsAction } from '../Favorites/actions';
import { EnumProductsSets } from 'Modules/Products/types';

export const setProductsSetsAction = (payload: IProductsSet[]): AnyAction => ({
  type: EnumProductTypes.SET_PRODUCTS_SETS,
  payload,
});

export const setSaleProductsSetAction = (payload: IProductsSet[]): AnyAction => ({
  type: EnumProductTypes.SET_SALE_PRODUCTS_SET,
  payload,
});

export const setProductsSetAction = (payload: IProductsSet, id: number | undefined): AnyAction => ({
  type: EnumProductTypes.SET_PRODUCTS_SET,
  payload,
  id,
});

export const setProductsSetCategoriesAction = (payload: ICategoryChild[]): AnyAction => ({
  type: EnumProductTypes.SET_PRODUCTS_SET_CATEGORIES,
  payload,
});

export const updateProductsSetAction = (payload: IProductsSet): AnyAction => ({
  type: EnumProductTypes.UPDATE_PRODUCTS_SET,
  payload,
});

export const startGetProductsSetAction = (): AnyAction => ({
  type: EnumProductTypes.START_GET_PRODUCTS_SET,
});

export const startGetProductsSetCategoriesAction = (): AnyAction => ({
  type: EnumProductTypes.START_GET_PRODUCTS_SET_CATEGORIES,
});

export const clearCurrentProductSetAction = (): AnyAction => ({
  type: EnumProductTypes.CLEAR_CURRENT_SET,
});

export const setSaleProductsSet = (shop_id: number): ThunkAction => async (dispatch: Dispatch, _, fetcher) => {
  try {
    const data = await ProductHttp.getProductsSets(shop_id, EnumProductsSets.sale_products, fetcher);
    await dispatch(setSaleProductsSetAction(data));
  } catch (e) {
    dispatch(setSaleProductsSetAction([]));
  }
};

export const setTagsSetsAction = (payload: IProductsSet[]): AnyAction => ({
  type: EnumProductTypes.SET_TAGS_SETS,
  payload,
});

export default {
  toggleProductToFavorites: (
    { model_id, favorite }: IProductFavoriteBase,
    productForAnalitic: IProduct,
    from: EnumFromName,
    from_product_page: boolean,
    currentFavoriteId?: string
  ): ThunkAction => async (dispatch, getState) => {
    const shop_id = getState().currentShop.id;
    const data = await ProductsHttp.toggleProductToFavorites({
      shop_id,
      model_id,
      favorite,
    });

    dispatch(getCurrentFavoriteProductsAction(shop_id, { category_id: currentFavoriteId }));
    dispatch(CategoriesActions.setFavoritesCategories());

    return Promise.resolve(data);
  },

  setProductsSetCategories: (set_id: number): ThunkAction => async (dispatch, _, fetcher) => {
    dispatch(startGetProductsSetCategoriesAction());

    const dataCategories = await ProductHttp.getFullProductsSetCategories(set_id, fetcher);
    await dispatch(setProductsSetCategoriesAction(dataCategories.list));
  },

  setProductsSet: (set_id: number, id: number | undefined, params?: IProductsSetRequest): ThunkAction => async (
    dispatch,
    _,
    fetcher
  ) => {
    dispatch(startGetProductsSetAction());

    const data = await ProductHttp.getFullProductsSet(set_id, params, fetcher);
    await dispatch(setProductsSetAction(data, id));
  },

  updateProductsSet: (set_id: number, params?: IProductsSetRequest) => async (dispatch: Dispatch) => {
    const data = await ProductHttp.getFullProductsSet(set_id, params);
    await dispatch(updateProductsSetAction(data));
  },

  setProductsSets: (shop_id: number): ThunkAction => async (dispatch: Dispatch, _, fetcher) => {
    try {
      const data = await ProductHttp.getProductsSets(shop_id, undefined, fetcher);

      await dispatch(setProductsSetsAction(data.filter(({ kind }) => kind !== EnumProductsSets.tag)));
    } catch (e) {
      dispatch(setProductsSetsAction([]));
    }
  },

  setTagsProductsSets: (shop_id: number): ThunkAction => async (dispatch: Dispatch, _, fetcher) => {
    try {
      const data = await ProductHttp.getTagsProductsSets(shop_id, fetcher);

      await dispatch(setTagsSetsAction(data));
    } catch (e) {
      dispatch(setTagsSetsAction([]));
    }
  },

  setSaleProductsSet,

  clearCurrentProductSet: () => (dispatch: Dispatch) => {
    dispatch(clearCurrentProductSetAction());
  },
};
