import { FontSize } from 'theme';
import React, { FC } from 'react';
import Text from 'Components/Typography/Text';
import { URL } from 'constants/urlMaps';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import ColoredLinkWrapper from 'Components/Link/ColoredLinkWrapper';
import ExternalLink from 'Components/ExternalLink';
import fetchDataOnServer from './Condition.server';

export const SSR_KEY = 'CONDITION';
export const SERVICE_OFFER_TEST_ID = 'SERVICE_OFFER_TEST_ID';
export const AGENT_OFFER_TEST_ID = 'AGENT_OFFER_TEST_ID';

const ConditionWrapper = styled(Text)`
  color: ${colors.greyish};
  padding: 10px;
`;

const ConditionLink = styled(ColoredLinkWrapper)`
  margin-left: 3px;
  color: ${colors.primaryMain};
`;

const ServiceOfferLink: FC<{ serviceOfferUrl?: string }> = ({ serviceOfferUrl }) => (
  <ConditionLink underlineHover>
    <ExternalLink href={serviceOfferUrl} newTab data-testid={SERVICE_OFFER_TEST_ID}>
      оферты сервиса
    </ExternalLink>
  </ConditionLink>
);

const Condition = () => {
  return (
    <>
      <ConditionWrapper size={FontSize.xs}>
        Нажимая «Оформить» вы соглашаетесь с условиями
        {
          <>
            <ServiceOfferLink serviceOfferUrl={URL.offer} />
          </>
        }
        .
      </ConditionWrapper>
    </>
  );
};

export default Condition;

Condition.fetchOnServer = fetchDataOnServer;
