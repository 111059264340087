import React from 'react';
import Icon, { IIcon } from './Icon';

const IncreaseIconBold: React.FC<IIcon> = props => (
  <Icon width="22" height="22" viewBox="0 0 10 11" {...props} fill="none">
    <path d="M1.40002 5.57715H8.90002" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
    <path d="M4.40003 9.13477L5.90002 2.01947" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" />
  </Icon>
);

export default IncreaseIconBold;
