import H from 'history';
import { EnumFromName } from 'constants/enums';
import { IProduct } from 'Modules/Products/types';
import { IAnalyticsParams } from 'utils/tagManager/types';
export interface IProductModalOptions {
  from?: EnumFromName;
  product?: IProduct;
  productsSetId?: number;
  analyticsParams?: IAnalyticsParams;
}

export const clearProductFromLocation = (history: H.History) => {
  const params = new URLSearchParams(history.location.search);
  if (params.get('product')) {
    params.delete('product');
    history.push({ search: params.toString() });
  }
};

export const setProductToLocation = (history: H.History, id: number, props: IProductModalOptions) => {
  const params = new URLSearchParams(history.location.search);
  params.set('product', String(id));
  history.push({ search: params.toString(), state: { hasPrevRoute: true, ...props } });
};
