import React from 'react';
import Icon, { IIcon } from './Icon';

const SubmitArrowIcon: React.FC<IIcon> = props => (
  <Icon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path d="M3 11H17" stroke="currentColor" strokeWidth="1.7" strokeLinecap="round" />
    <path
      d="M13 17L19 11L13 5"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </Icon>
);

export default SubmitArrowIcon;
