import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const CheckIconBold: FC<IIcon> = props => (
  <Icon width="10" height="10" viewBox="0 0 10 10" fill="none" {...props}>
    <path
      d="M1.5 5L4.02 7.5L8.5 2.5"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default CheckIconBold;
