import React, { FC } from 'react';
import Portal from 'Components/Modal/Portal';
import BottomSheet, { IBottomSheet } from './BottomSheet';
import { useServer } from 'utils/hooks/useServer';

const BottomSheetComponent: FC<IBottomSheet> = props => {
  const isServer = useServer();
  if (isServer) {
    return null;
  }

  return (
    <Portal container={document.getElementById('portal-root') as HTMLElement}>
      <BottomSheet {...props} />
    </Portal>
  );
};

export default BottomSheetComponent;
