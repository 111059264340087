import { IProduct } from 'Modules/Products/types';
import { ICategory } from '../../Modules/Categories/types';
import { isNil } from 'ramda';
import { ICartState } from 'Modules/Cart/types';
import { calculateProductPrice } from 'utils/helpers';

export const sortProductsByCategories = (
  products: IProduct[],
  categories: ICategory[],
  calculateUnavaliable = false
) => {
  if (isNil(products && categories)) return [];

  const categoriesList = [];
  const allProducts = products;

  for (let i = 0, l = categories.length, category, products, totalPrice; i < l; ++i) {
    category = categories[i];
    products = [];
    totalPrice = 0;

    for (let j = 0, k = allProducts.length, cartProduct; j < k; ++j) {
      cartProduct = allProducts[j];

      if (cartProduct.root_category_id === category.id) {
        if (calculateUnavaliable) {
          totalPrice += calculateProductPrice(cartProduct);
        } else if (cartProduct.available) {
          totalPrice += calculateProductPrice(cartProduct);
        }

        products.push(cartProduct);
      }
    }

    if (products.length) {
      categoriesList.push({
        id: category.id,
        active: false,
        name: category.name,
        totalPrice: totalPrice,
        products,
      });
    }
  }

  return categoriesList;
};

export const getLimitsWithProductCount = (cart: ICartState) => {
  return (
    cart.model_limits
      // сортируем в алфавитном порядке по limit.name
      .sort((limitA, limitB) => limitA.name.localeCompare(limitB.name))
      // добавляем в лимиты поле productCount, чтобы было
      // понятно насколько превышен отдельный лимит
      .map(limit => ({
        ...limit,
        // делаем массив, каждый элемент которого - количество продукта в корзине
        productCount: limit.model_ids
          .map(
            modelId =>
              // находим в корзине продукт с подходящим model_id
              // и возращаем его кол-во, в зависимости от единиц измерения лимита
              cart.products.find(({ model_id }) => modelId === model_id)?.[
                limit.unit === 'piece' ? 'amount' : 'weight'
              ] || 0
          )
          // суммируем все элементы массива и молучаем общее
          // количество продуктов, превышающих отдельный лимит
          .reduce((previousValue, currentValue) => previousValue + currentValue, 0),
      }))
  );
};
