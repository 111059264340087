import React, { FC, useEffect, useRef } from 'react';
import { animated, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import { clamp } from 'ramda';
import { Background, Body, Container, Overlay } from './styled';
import { isBrowser } from 'utils/helpers';
import { useServer } from 'utils/hooks/useServer';

export interface IBottomSheet {
  className?: string;
  isOpen?: boolean;
  close: () => void;
  open?: () => void;
  onExpanded?: () => void;
  onCollapsed?: () => void;
  snapPoint?: number;
  snapPointToTop?: number;
  hidden?: boolean;
}

const HEIGHT_WINDOW = isBrowser ? window.innerHeight : 0;

const bottomSheetConfig = {
  tension: 500,
  friction: 50,
};

const BottomSheet: FC<IBottomSheet> = ({
  children,
  isOpen = false,
  close,
  open,
  onExpanded,
  onCollapsed,
  snapPoint = 500,
  snapPointToTop = 56,
  hidden = false,
  className,
}) => {
  const isServer = useServer();
  const getY = (o: boolean): number => (o ? snapPointToTop : HEIGHT_WINDOW - snapPoint);

  const prevState = useRef<boolean>(isOpen);
  const refOverlay = useRef<HTMLDivElement>(null);
  const [{ y }, setY] = useSpring(() => ({
    y: getY(isOpen),
    config: bottomSheetConfig,
  }));

  const collapsedY = getY(false);
  const expandedY = getY(true);

  useEffect(() => {
    setY({ y: !isOpen ? collapsedY : expandedY });
  }, [collapsedY, expandedY, isOpen, setY]);

  useEffect(() => {
    const listener = (e: TouchEvent) => e.preventDefault();
    const startListenTouch = () => refOverlay.current?.addEventListener('touchmove', listener);
    const stopListenTouch = () => refOverlay.current?.removeEventListener('touchmove', listener);
    if (isOpen) {
      startListenTouch();
    } else {
      stopListenTouch();
    }
    return stopListenTouch;
  }, [isOpen]);

  useEffect(() => {
    if (prevState.current !== isOpen) {
      prevState.current = isOpen;
      if (isOpen) {
        onExpanded && onExpanded();
      } else {
        onCollapsed && onCollapsed();
      }
    }
  }, [isOpen, onCollapsed, onExpanded]);

  const calculateNextY = (down: boolean, currentY: number, movementY: number, velocity: number, threshold: number) => {
    if (down) {
      const y = currentY + movementY;
      if (y > collapsedY) return collapsedY;
      if (y < expandedY) return expandedY;
      return y;
    }

    if (isOpen) {
      return movementY > threshold ? collapsedY : expandedY;
    } else {
      return movementY < -threshold ? expandedY : collapsedY;
    }
  };

  const bind = useDrag(({ down, movement: [, movementY], velocity, memo = y.get() }) => {
    velocity = clamp(1, 8, velocity);

    const threshold = 100 / velocity;

    if (!down) {
      if (isOpen && movementY > threshold) {
        close();
      } else if (!isOpen && movementY < -threshold) {
        open && open();
      }
    }

    const nextY = calculateNextY(down, memo, movementY, velocity, threshold);

    setY({
      y: nextY,
    });

    return memo;
  });

  return (
    <Overlay
      ref={refOverlay}
      className={className}
      $isOpen={isOpen}
      $isHide={!isOpen && !snapPoint}
      $isServer={isServer}
    >
      {isOpen && <Background onClick={close} />}
      <animated.div
        {...bind()}
        style={{
          pointerEvents: 'all',
          ...(snapPoint && { height: snapPoint }),
          ...(!isServer && { y }),
        }}
      >
        <Container $maxHeight={isServer ? snapPoint : HEIGHT_WINDOW - snapPointToTop}>
          <Body $hidden={hidden}>{children}</Body>
        </Container>
      </animated.div>
    </Overlay>
  );
};

export default BottomSheet;
