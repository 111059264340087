import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const QuestionIcon: FC<IIcon> = props => (
  <Icon width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      id="a"
      d="M6 0a6 6 0 1 1 0 12A6 6 0 0 1 6 0zm.688 9.953V8.578H5.313v1.375h1.375zm.367-3.165a2.391 2.391 0 0 0 1.351-2.163A2.438 2.438 0 0 0 6 2.219a2.406 2.406 0 0 0-2.406 2.406h1.375c0-.57.461-1.031 1.031-1.031a1.059 1.059 0 0 1 1.031 1.031c0 .395-.225.755-.58.928a2.107 2.107 0 0 0-1.138 1.902v.092h1.375v-.092a.728.728 0 0 1 .367-.667z"
    />
  </Icon>
);

export default QuestionIcon;
