import React, { useState } from 'react';

import Box from 'Components/Grid/Box';
import { COMMENT_PRODUCT_CONTAINER_TEST_ID, PRODUCT_COMMENT_BUTTON_MOBILE_TEST_ID } from './constants';
import CenteredClosableModal from 'Components/Modal/CenteredClosableModal';
import { CommentButton } from './styled';
import { EnumSize, EnumTheme } from 'constants/enums';
import { FavoriteIcon } from 'Components/Product/Favorite';
import Flex from 'Components/Grid/Flex';
import { FontSize } from 'theme';
import { IProduct } from 'Modules/Products/types';
import ProductImage from 'Components/Product/Image';
import { ProductPlaceholder } from 'Components/Header/SearchProduct/Loading';
import ProductPrice from 'Components/Product/Price';
import Text from 'Components/Typography/Text';
import Textarea from 'Components/Textarea';
import ThemeButton from 'Components/ThemedButton';
import Title from 'Components/Typography/Title';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import useModalState from 'utils/hooks/useModalState';

export const TEXTAREA_COMMENT_TEST_ID = 'TEXTAREA_COMMENT_TEST_ID';
export const TEXTAREA_COMMENT_REMOVE_BTN_ID = 'TEXTAREA_COMMENT_REMOVE_BTN_ID';
export const TEXTAREA_COMMENT_SUBMIT_BTN_ID = 'TEXTAREA_COMMENT_SUBMIT_BTN_ID';

interface IModalProps {
  comment: string;
  onSubmit: (value: string) => void;
  editDisabled?: boolean;
  product?: IProduct;
  className?: string;
  external?: boolean;
  externalVisible?: boolean;
  externalHide?: () => void;
}

const Container = styled.form`
  background-color: ${colors.white};
  padding: 16px 16px 20px;
  min-height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const ModalTitle = styled(Title)`
  margin-bottom: 18px;
  margin-right: 32px;
`;

const Product = styled(Flex)`
  margin-bottom: 20px;
  position: relative;
`;

const Favorite = styled(FavoriteIcon)`
  position: absolute;
  top: -4px;
  left: 4px;
`;

const Image = styled(ProductImage)`
  margin: 0 12px 0 0;
`;

const Name = styled(Text).attrs({ size: FontSize.m })`
  margin-bottom: 2px;
`;

const Weight = styled(Text).attrs({ color: colors.warmGrey })`
  margin-bottom: 12px;
`;

const TextareaContainer = styled.div`
  margin-bottom: 20px;
`;

const CommentTextarea = styled(Textarea)`
  && {
    min-height: 126px;
  }
`;

const SubmitButton = styled(ThemeButton)`
  margin-top: auto;
  margin-bottom: 8px;
`;

export const CommentProductModal = ({
  comment = '',
  onSubmit,
  editDisabled = false,
  product,
  className,
  /**
   * следующие 3 пропа это костыль, чтобы не сломать другие карточки, потому что за каким-то хуем компонент модалки комментария идет в паре с кнопкой комментария.
   * работает следующим образом:
   * externalVisible это внешнее состояние модалки, которое используется вместо внутреннего
   * externalHide - внешний коллбэк для скрытия модалки
   * external - смотрим на это свойство используем внешнее управление состоянием, усли true
   * в будущем внутреннее состояние будет полностью выпилено
   */
  externalVisible,
  externalHide,
  external,
}: IModalProps) => {
  const [commentText, setCommentText] = useState<string>(comment);
  const { isVisible, showModal, hideModal } = useModalState();

  const handleCommentChange = (value: string) => setCommentText(value);

  const hideCallback = () => {
    externalHide ? externalHide() : hideModal();
  };

  const handleSubmitForm = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit(commentText);
    hideCallback();
  };

  const handleRemoveComment = () => {
    onSubmit('');
    hideCallback();
  };

  return (
    <>
      {!external && (
        <CommentButton
          className={className}
          data-testid={PRODUCT_COMMENT_BUTTON_MOBILE_TEST_ID}
          onClick={showModal}
          isEmpty={!comment}
        />
      )}
      <CenteredClosableModal show={externalVisible || isVisible} closeModal={hideCallback}>
        <Container data-testid={COMMENT_PRODUCT_CONTAINER_TEST_ID} onSubmit={handleSubmitForm}>
          <ModalTitle level="3">Комментарий к товару</ModalTitle>
          {product ? (
            <Product>
              {product.favorite && <Favorite size={EnumSize.xs} isActive />}
              <Box>
                <Image image={product.image} size={EnumSize.sm} />
              </Box>

              <Flex column>
                <Name>{product.name}</Name>
                <Weight>{product.display_weight}</Weight>
                <ProductPrice
                  price={product.price}
                  old_price={product.old_price}
                  size={EnumSize.xs}
                  sale={product.sale}
                  sale_end_date={product.sale_end_date}
                  type={product.type}
                  amount={product.amount}
                  withOldPrice
                />
              </Flex>
            </Product>
          ) : (
            <ProductPlaceholder width={'100%'} />
          )}

          <TextareaContainer>
            <CommentTextarea
              testIdTextarea={TEXTAREA_COMMENT_TEST_ID}
              text={comment}
              onChange={handleCommentChange}
              placeholder="Пожелания по заменам, внешнему виду. Укажите, если товар ключевой."
              maxLength={150}
              disabled={editDisabled}
            />
          </TextareaContainer>
          <SubmitButton
            data-testid={TEXTAREA_COMMENT_SUBMIT_BTN_ID}
            fluid
            type="submit"
            disabled={editDisabled}
            use={EnumTheme.roundRed}
          >
            Сохранить
          </SubmitButton>
          <ThemeButton
            data-testid={TEXTAREA_COMMENT_REMOVE_BTN_ID}
            use={EnumTheme.roundDarkGray}
            fluid
            onClick={handleRemoveComment}
            disabled={!comment || editDisabled}
          >
            Удалить
          </ThemeButton>
        </Container>
      </CenteredClosableModal>
    </>
  );
};
