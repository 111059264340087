import React from 'react';
import { Box, Flex } from '@igooods/ui-kit';
import { FontFamily, FontSize } from 'theme';
import { ThemeButton } from 'Components/ThemedButton';
import AddToUserSetIcon from 'Components/Icon/AddToUserSetIcon';
import { Text } from 'Components/Typography';
import { EnumButtonSize, EnumTheme } from 'constants/enums';

const TextToggler: React.FC = () => {
  return (
    <ThemeButton use={EnumTheme.roundDarkGray} size={EnumButtonSize.small}>
      <Flex middle pl={1} pr={1.75}>
        <AddToUserSetIcon />
        <Box ml={1.25}>
          <Text fontFamily={FontFamily.mainFont} size={FontSize.m}>
            Добавить в набор
          </Text>
        </Box>
      </Flex>
    </ThemeButton>
  );
};

export default TextToggler;
