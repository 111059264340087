import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@igooods/ui-kit';
import useModalState from 'utils/hooks/useModalState';
import { IProduct } from 'Modules/Products/types';
import UserSetsActions from 'Modules/UserSets/actions';
import { getUserSetsList } from 'Modules/UserSets/selectors';
import { IUserSetListItem } from 'Modules/UserSets/types';
import Dropdown from 'Components/Dropdown';
import IconToggler from './IconToggler';
import { ColorPoint, DropdownContent, DropdownItem, DropdownText } from './styled';
import TextToggler from './TextToggler';
import IncreaseIconBold from 'Components/Icon/IncreaseIconBold';
import CheckIconBold from 'Components/Icon/CheckIconBold';

export enum EnumUserSetsDropdownPosition {
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'RIGHT',
}
interface IAddToUserSetDropdownProps {
  icon?: boolean;
  product: IProduct;
  position?: EnumUserSetsDropdownPosition;
}

const AddToUserSetDropdown: React.FC<IAddToUserSetDropdownProps> = ({
  icon,
  product,
  position = EnumUserSetsDropdownPosition.CENTER,
}) => {
  const dispatch = useDispatch();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const userSetsList = useSelector(getUserSetsList);
  const { isVisible: show, showModal: open, hideModal: close } = useModalState();

  const handleCreateUserSetAndAddProduct = () => {
    close();
    dispatch(UserSetsActions.openUserSetEditor(null, [product.model_id]));
  };

  const clickHadler = (userSetsListItem: IUserSetListItem) => {
    close();
    dispatch(UserSetsActions.editUserSetListItem(userSetsListItem, product));
  };

  const toggler = icon ? <IconToggler /> : <TextToggler />;

  return (
    <Dropdown isOpen={show} toggle={toggler} onToggle={open} onClickOutside={close}>
      <DropdownContent ref={contentRef} position={position}>
        <DropdownItem isActive={false} mt={0.25}>
          <Box mr={1.75}>
            <IncreaseIconBold width="10" />
          </Box>
          <DropdownText onClick={handleCreateUserSetAndAddProduct}>В новый набор</DropdownText>
        </DropdownItem>
        {userSetsList.map(userSetsListItem => {
          const isActive = Boolean(userSetsListItem.model_ids?.includes(product.model_id));
          return (
            <DropdownItem
              key={userSetsListItem.id}
              isActive={isActive}
              onClick={!isActive ? () => clickHadler(userSetsListItem) : undefined}
            >
              <Box mr={2}>
                <ColorPoint color={userSetsListItem.color} />
              </Box>
              <Box mr>
                <DropdownText>{userSetsListItem.name}</DropdownText>
              </Box>
              {isActive && <CheckIconBold width="12" />}
            </DropdownItem>
          );
        })}
      </DropdownContent>
    </Dropdown>
  );
};

export default AddToUserSetDropdown;
