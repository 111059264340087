import { ReduxDispatch } from 'reduxStore/types';
import { setProductInOrder } from '../OrdersHistory/actions';
import OrderActions, { updateProductInOrder } from '../Cart/actions';
import { CHANGE_PRODUCT_AMOUNT_DELAY_MS } from '../../Components/Product/constants';
import { IProductProps } from './types';
import debounce from 'lodash.debounce';
import OrdersHttp from '../Cart/http';
import { EnumProductType } from 'Modules/Products/types';
import { TagManagerAddProductToCart, TagManagerRmProductFromCart } from 'utils/tagManager';

const incProductAmountInOrder = (
  dispatch: ReduxDispatch,
  props: IProductProps,
  updatedAmount: number,
  amountOfChange: number
) => {
  const { inCurrentOrder, product, from, productsSetId, analyticsParams } = props;
  const additionalParams = { amountOfChange };
  if (inCurrentOrder) {
    dispatch(setProductInOrder({ product_id: product.id, amount: updatedAmount }));
    TagManagerAddProductToCart(product, additionalParams, from, analyticsParams, props.orderId, productsSetId, true);
    return;
  } else {
    return dispatch(
      OrderActions.addToOrder(
        {
          product_id: product.id,
          amount: updatedAmount,
          category_id: product.category_id,
        },
        product,
        from,
        productsSetId,
        additionalParams,
        analyticsParams
      )
    );
  }
};
const decProductAmountInOrder = (
  dispatch: ReduxDispatch,
  props: IProductProps,
  updatedAmount: number,
  amountOfChange: number
) => {
  const { inCurrentOrder, product, from, productsSetId, analyticsParams } = props;
  const additionalParams = { amountOfChange };
  if (inCurrentOrder) {
    dispatch(setProductInOrder({ product_id: product.id, amount: updatedAmount }));
    TagManagerRmProductFromCart(product, additionalParams, from, analyticsParams, props.orderId, productsSetId, true);
    return;
  } else {
    return dispatch(
      OrderActions.removeFromOrder(
        {
          product_id: product.id,
          amount: updatedAmount,
        },
        product,
        from,
        productsSetId,
        additionalParams,
        analyticsParams
      )
    );
  }
};

let firstActionAmount = 0;
let prevProductId: number | undefined;

const setAmount = async (dispatch: ReduxDispatch, props: IProductProps, newAmount: number) => {
  const amountOfChange = Math.abs(newAmount - firstActionAmount);
  try {
    if (firstActionAmount < newAmount) {
      await incProductAmountInOrder(dispatch, props, newAmount, amountOfChange);
    } else {
      await decProductAmountInOrder(dispatch, props, newAmount, amountOfChange);
    }
  } catch (e) {
    // при ошибке сначала мы возвращаемся к первому количеству, которое пришло, и только потом его сбрасываем
    dispatch(updateProductInOrder({ ...props.product, amount: firstActionAmount }));
  } finally {
    // при успешном или не успешном выполнении запроса изменения заказа количество обнуляется
    // сбрасываем id предыдущего товара, если текущий вызов метода был для этого же товара
    firstActionAmount = 0;
    prevProductId = prevProductId === props.product.id ? undefined : prevProductId;
  }
};

const setAmountDebounced = debounce(setAmount, CHANGE_PRODUCT_AMOUNT_DELAY_MS, { trailing: true });

export const changeProductAmount = (props: IProductProps, newAmount: number): any => (dispatch: ReduxDispatch) => {
  const oldAmount = props.product.amount;
  OrdersHttp.cancelProductChangeRequest(props.product.id);
  // важно сохранять самое первое значение количества перед тем, как запускать экшн
  if (!firstActionAmount) firstActionAmount = oldAmount;

  if (!props.inCurrentOrder) {
    dispatch(
      updateProductInOrder({
        ...props.product,
        amount: newAmount,
        ...(props.product.type === EnumProductType.weight && { weight: props.product.item_weight * newAmount }),
      })
    );
  }
  /**
   * здесь разруливается следующая история:
   * - юзер натыкивает один товар
   * - тут же натыкивает другой
   * - если запрос первого товара еще не ушёл, то он задебаунсится и будет отменен,
   * если только мы принудительно не отправим его с помощью flush
   */

  if (prevProductId && prevProductId !== props.product.id) {
    setAmountDebounced.flush();
  }

  prevProductId = props.product.id;
  setAmountDebounced(dispatch, props, newAmount);
};
