import React, { FC } from 'react';
import { EnumButtonSize, EnumTheme } from 'constants/enums';
import styled from 'styled-components';
import { isNil } from 'ramda';
import ThemeButton from 'Components/ThemedButton';
import { colors } from 'constants/colors';
import { FontSize } from 'theme';
import Text from 'Components/Typography/Text';
import ChevronDownMiniIcon from 'Components/Icon/ChevronDownMiniIcon';
import DropdownComponent, { IDropdownComponentProps } from './DropdownComponent';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import useModalState from 'utils/hooks/useModalState';

export const DROPDOWN_SELECT_CHEVRON_TEST_ID = 'DROPDOWN_SELECT_CHEVRON_TEST_ID';
export const DROPDOWN_SELECT_BUTTON_TEST_ID = 'DROPDOWN_SELECT_BUTTON_TEST_ID';

interface IDropdownSelectChevron {
  isOpen?: boolean;
}

const getPaddingBySize = (size: EnumButtonSize) => {
  switch (size) {
    case EnumButtonSize.small:
      return '32px';
    default:
      return '46px';
  }
};

export const Button = styled(ThemeButton)`
  padding-left: 12px;
  padding-right: ${({ size }) => getPaddingBySize(size)};
  display: flex;
  user-select: none;
  width: 100%;
  ${addStyleIfPropTruly(
    '$isOpened',
    `
    color: ${colors.darkGrey};
    background-color: ${colors.silverFour};
  `
  )}

  i {
    margin-right: 5px;
    color: currentColor;
  }

  div {
    text-align: left;
    justify-content: flex-start;
  }
`;

const getFontSizeByButtonSize = (size: EnumButtonSize): FontSize => {
  switch (size) {
    case EnumButtonSize.small:
      return FontSize.xs;
    default:
      return FontSize.s;
  }
};

const Chevron = styled.i<IDropdownSelectChevron>`
  position: absolute;
  right: 8px;
  top: 50%;
  transition: transform 180ms ease-in 16ms;

  ${({ isOpen }) =>
    isOpen ? `transform: translateY(-50%) rotate(-180deg);` : `transform: translateY(-50%) rotate(-0deg);`};
`;

export interface IDropdownSelectProps
  extends Omit<IDropdownComponentProps, 'toggle' | 'close' | 'isOpen' | 'onToggle' | 'maxWidth'> {
  use?: EnumTheme;
  disabled?: boolean;
  testIdButton?: string;
  size?: EnumButtonSize;
  withChevron?: boolean;
}

export const DropdownSelect: FC<IDropdownSelectProps> = ({
  use = EnumTheme.quaternary,
  className,
  classNameContent,
  list,
  disabled,
  selectedItem,
  onSelectDropdownItem,
  testId,
  testIdButton = DROPDOWN_SELECT_BUTTON_TEST_ID,
  testIdContent,
  testIdItem,
  size = EnumButtonSize.normal,
  withChevron = false,
  withMb = false,
}: IDropdownSelectProps) => {
  const { isVisible: isOpen, hideModal: close, toggleModal: toggleHandler } = useModalState();

  const toggle = ({ isActive }: { isActive: boolean }) => {
    return (
      <Button $isOpened={isActive} disabled={disabled} use={use} size={size} testId={testIdButton}>
        <Text overflow size={getFontSizeByButtonSize(size)}>
          {!isNil(selectedItem) && list[selectedItem]}
        </Text>
        {list.length > 1 && withChevron && (
          <Chevron isOpen={isActive} data-testid={DROPDOWN_SELECT_CHEVRON_TEST_ID}>
            <ChevronDownMiniIcon color={colors.pinkishGrey} />
          </Chevron>
        )}
      </Button>
    );
  };

  return (
    <DropdownComponent
      itemSize={size}
      maxWidth="300px"
      onToggle={toggleHandler}
      {...{
        isOpen,
        close,
        toggle,
        className,
        classNameContent,
        list,
        onSelectDropdownItem,
        testId,
        testIdContent,
        testIdItem,
        withMb,
        selectedItem,
      }}
    />
  );
};

export default DropdownSelect;
