import React, { memo } from 'react';
import { EnumSize, ProductStates } from 'constants/enums';
import { Size } from 'constants/types';
import { IProductImage } from 'Modules/Products/types';
import styled, { css } from 'styled-components';
import { mediaSmDown, mediaMdDown } from 'theme/helpers/css';
import defaultProductImage from 'assets/images/default-product-image.svg';
import { PRODUCT_IMAGE_TEST_ID, PRODUCT_IMAGE_WRAPPER_TEST_ID } from './constants';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import Flex from 'Components/Grid/Flex';

interface ISizable {
  size: Size;
}

interface IImage extends ISizable {
  state?: ProductStates;
}

interface IProductWrapper extends ISizable {
  empty: boolean;
}

export const ImageWrapper = styled(Flex).attrs({ center: true, middle: true })<IProductWrapper>`
  position: relative;
  flex-shrink: 0;
  margin: 0 auto 8px;
  ${addStyleIfPropTruly(
    'empty',
    `
  background-image: url(${defaultProductImage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  `
  )}
  ${mediaSmDown`
    margin-bottom: 20px;
  `}
  ${({ size }) => {
    switch (size) {
      case EnumSize.lg:
        return css`
          max-width: 352px;
          max-height: 352px;
          width: 100%;
          height: 100%;

          ${mediaSmDown`
            width: 232px;
            height: 232px;
          `}
        `;
      case EnumSize.md:
        return css`
          width: 160px;
          height: 160px;

          ${mediaSmDown`
            width: 108px;
            height: 108px;
          `}
        `;
      case EnumSize.sm:
        return css`
          width: 80px;
          height: 80px;
        `;
    }
  }}
`;

export const Image = styled.img<IImage>`
  max-width: 100%;
  ${({ size }) => {
    switch (size) {
      case EnumSize.lg:
        return css`
          max-width: 352px;
          max-height: 352px;
          width: auto;

          /* на разрешении 1024 фото товара слишком конское */
          ${mediaMdDown`
            max-width: 232px;
            max-height: 232px
          `}
        `;
      case EnumSize.md:
        return css`
          max-width: 160px;
          max-height: 160px;

          ${mediaSmDown`
            max-width: 108px;
            max-height: 108px;
          `}
        `;
      case EnumSize.sm:
        return css`
          max-width: 80px;
          max-height: 80px;
        `;
    }
  }}
  ${({ state }) => state === 'not_available' && `filter: grayscale(100%);`}
`;

function calcImageSize(image: IProductImage, size: EnumSize) {
  switch (size) {
    case EnumSize.sm:
      return image.small;
    case EnumSize.md:
      return image.medium;
    default:
      return image.large;
  }
}

interface IProductImageProps {
  className?: string;
  image?: IProductImage;
  size?: EnumSize;
  state?: ProductStates;
}

export const ProductImage: React.FC<IProductImageProps> = ({
  className,
  image,
  size = EnumSize.md,
  state = ProductStates.inBasket,
}) => {
  const imageBySize = image && calcImageSize(image, size);

  return (
    <ImageWrapper data-testid={PRODUCT_IMAGE_WRAPPER_TEST_ID} size={size} empty={!imageBySize}>
      {imageBySize && (
        <Image src={imageBySize} className={className} data-testid={PRODUCT_IMAGE_TEST_ID} size={size} state={state} />
      )}
    </ImageWrapper>
  );
};

export default memo(ProductImage);
