import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import OrderActions from 'Modules/Cart/actions';
import { colors } from '../../../constants/colors';
import { EMPTY_STRING } from 'constants/constants';
import ThemeButton from 'Components/ThemedButton';
import { getCartState } from 'Modules/Cart/selectors';
import Input from 'Components/Input';
import { EnumSize } from 'constants/enums';
import styled from 'styled-components';
import { mediaSmDown } from 'theme/helpers/css';
import SubmitArrowIcon from '../../../Components/Icon/SubmitArrowIcon';
import { FontWeight } from '../../../theme';

export const PROMOCODE_MODAL_FORM_TEST_ID = 'PROMOCODE_MODAL_FORM_TEST_ID';
export const PROMOCODE_MODAL_INPUT_TEST_ID = 'PROMOCODE_MODAL_INPUT_TEST_ID';
export const PROMOCODE_MODAL_BTN_TEST_ID = 'PROMOCODE_MODAL_BTN_TEST_ID';

const Form = styled.form`
  flex-grow: 1;
  display: flex;
  flex-direction: row;

  /* TODO Jira: https://igooods.atlassian.net/browse/FS-176 */
  .modal-promocode-input {
    ${mediaSmDown`
      height: 35px;
      font-size: 12px;
    `}
  }
`;

const SubmitBtn = styled(ThemeButton)`
  background-color: ${colors.primaryMain};
  border-radius: 100px;
  align-items: center;
  padding: 0px;
  width: 36px;
  height: 36px;

  &:disabled {
    opacity: 50%;
  }

  &:hover {
    background-color: ${colors.primaryAction};
  }

  ${mediaSmDown`
     height: 48px;
     width: 48px;
  `}
`;

const ArrowIcon = styled(SubmitArrowIcon)`
  width: 17px;
  height: 17px;

  ${mediaSmDown`
     height: 22px;
     width: 22px;
  `}
`;

const PromoInput = styled(Input)<{
  errorMsg?: any;
}>`
  height: 40px;
  border-radius: 100px;
  background-color: ${colors.light200};
  color: ${colors.dark300};
  font-family: Montserrat;
  font-weight: ${FontWeight.normal};
  font-size: 14px;

  ${mediaSmDown`
    height: 48px !important;
    font-size: 14px !important;
  `}

  ${({ errorMsg }) => Boolean(errorMsg) && `border: 1px solid ${colors.brinkPink}`}
`;

const PromoCodeInput = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  width: 100%;

  div {
    width: 100% !important;
  }

  span {
    padding: 14px 8px;

    ${mediaSmDown`
      padding: 8px
    `}
  }
`;

const Promocode: React.FC = () => {
  const { promocode } = useSelector(getCartState);
  const dispatch = useDispatch();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [newPromocode, setNewPromocode] = useState(EMPTY_STRING);
  const [isDisabledSubmit, setDisabledSubmit] = useState(true);
  const [errorMsg, setErrorMsg] = useState();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      setErrorMsg(EMPTY_STRING);
      setLoadingBtn(true);
      await dispatch(OrderActions.addPromocode(newPromocode));
      setNewPromocode(EMPTY_STRING);
    } catch (e) {
      setLoadingBtn(false);
      setErrorMsg(e.response.data.error);
    } finally {
      setLoadingBtn(false);
    }
  };

  const handleChangeInput = useCallback((value: string) => {
    setNewPromocode(value);
    setErrorMsg('');
  }, []);

  useEffect(() => {
    const statePromocode = newPromocode && newPromocode.toLowerCase() !== promocode?.toLowerCase() && !errorMsg;

    setDisabledSubmit(!statePromocode);
  }, [newPromocode, promocode, errorMsg]);

  return (
    <Form onSubmit={handleSubmit} data-testid={PROMOCODE_MODAL_FORM_TEST_ID}>
      <PromoCodeInput>
        <PromoInput
          sizeName={EnumSize.lg}
          onChangeInput={handleChangeInput}
          defaultValue={newPromocode}
          errorMsg={errorMsg}
          testId={PROMOCODE_MODAL_INPUT_TEST_ID}
          className="modal-promocode-input"
        />

        <SubmitBtn type="submit" disabled={isDisabledSubmit} isLoading={loadingBtn} fluid>
          <ArrowIcon />
        </SubmitBtn>
      </PromoCodeInput>
    </Form>
  );
};

export default Promocode;
