import { RefObject, useEffect } from 'react';
import { IProductHTMLElement } from '../tagManager/types';
import { IProduct } from '../../Modules/Products/types';

export default function useIntersectionObserveProduct(
  intersectionObserver: IntersectionObserver | undefined,
  analyticsRef: RefObject<HTMLDivElement>,
  product: IProduct
): void {
  useEffect(() => {
    const element = analyticsRef.current as IProductHTMLElement;

    if (!intersectionObserver || !element) return undefined;

    element.product = product;
    intersectionObserver.observe(element);

    return () => {
      intersectionObserver.unobserve(element);
    };
  }, [analyticsRef, product, intersectionObserver]);
}
