import React from 'react';
import styled from 'styled-components';
import { mediaSmDown } from 'theme/helpers/css';
import CircledCloseButtonIcon from 'Components/Icon/CircledCloseButtonIcon';

const CloseButtonContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  height: 48px;
  width: 48px;
  cursor: pointer;
  transition: 0.3s ease filter;

  svg {
    width: 100%;
    height: 100%;
  }

  &:hover {
    filter: brightness(98%);
  }

  ${mediaSmDown`
    height: 38px;
    width: 38px;
  `}
`;

const ModalCloseButton = ({ onClick }: { onClick: () => void }) => (
  <CloseButtonContainer onClick={onClick}>
    <CircledCloseButtonIcon />
  </CloseButtonContainer>
);

export default ModalCloseButton;
