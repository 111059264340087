import React from 'react';
import Icon, { IIcon } from './Icon';
import { colors } from '../../constants/colors';

const CircledCloseButtonIcon: React.FC<IIcon> = ({ color = colors.light300, ...props }) => (
  <Icon width="48" height="48" viewBox="0 0 48 48" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.12876 43.8712C-3.30177 36.4406 -0.429013 21.5201 10.5454 10.5456C21.5205 -0.429598 36.4407 -3.30114 43.8713 4.12823C51.3018 11.5588 48.429 26.4793 37.454 37.4545C26.4796 48.429 11.5593 51.3018 4.12876 43.8712Z"
      fill={color}
    />
    <path d="M29.999 17.9512L18 29.9502" stroke={colors.dark200} strokeWidth="1.7" strokeLinecap="round" />
    <path d="M27.5745 29.9492L20.4238 17.9502" stroke={colors.dark200} strokeWidth="1.7" strokeLinecap="round" />
  </Icon>
);

export default CircledCloseButtonIcon;
