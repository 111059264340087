import { PRODUCT_LINK_TEST_ID, PRODUCT_TEST_ID } from './constants';
import { Transitions } from 'theme';
import { coverParent, mediaMdDown, mediaMobile, mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import Box from 'Components/Grid/Box';
import CommentIcon from 'Components/Icon/CommentIconNew';
import Flex from 'Components/Grid/Flex';
import { Link } from 'react-router-dom';
import React from 'react';
import ThemeButton from 'Components/ThemedButton';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import { colors } from 'constants/colors';
import styled, { css, DefaultTheme } from 'styled-components';
import { hideBeforeSm } from '../../theme/helpers';
import { Grid, FavoriteButton, GridCard, QuantityControls, IconRemoveButton } from '@igooods/ui-kit';
import { ProductCardTheme } from '@igooods/ui-kit/lib/components/ProductCard/theme';
import { EnumButtonSize, ProductStates } from 'constants/enums';
import LikeIcon from '../Icon/LikeIcon';

interface ICommentButtonProps {
  isEmpty: boolean;
  theme: ProductCardTheme;
}

export const ControlsRedButton = styled(ThemeButton).attrs(({ size = EnumButtonSize.small }) => ({
  use: 'roundRed',
  size,
  useDesktopHeight: true,
}))`
  flex-shrink: 0;
  caret-color: ${colors.light200};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;

  &:hover,
  &:active,
  &:focus {
    flex-shrink: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }

  span {
    width: max-content;
  }

  ${mediaSmDown`
    height: 42px;
  `}
`;

export const ControlsButton = styled(ThemeButton).attrs(({ size = EnumButtonSize.small }) => ({
  use: 'redesign',
  size,
  useDesktopHeight: true,
}))`
  flex-shrink: 0;
  border: none;
  background-color: ${colors.primaryMain};
  color: ${colors.white};
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;

  &:hover,
  &:active,
  &:focus {
    flex-shrink: 0;
    background-color: ${colors.primaryAction};
    color: ${colors.white};
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    border: none;
  }

  span {
    width: max-content;
  }

  ${mediaSmDown`
    height: 42px;
  `}
`;

export const CommentButton = styled(ThemeButton).attrs<ICommentButtonProps>(({ theme: { comment } }) => ({
  icon: true,
  children: (
    <CommentIcon
      width={comment?.btn.icon.width}
      height={comment?.btn.icon.height}
      color={comment?.btn.icon.defaultColor}
    />
  ),
}))<ICommentButtonProps>`
  padding: 4px;
  border-radius: 6px;
  height: 24px;
  width: 24px;
  ${({ theme: { comment } }) => css`
    background-color: ${comment?.btn.bgColor};
    &:hover {
      background-color: ${comment?.btn.hoverBgColor};
    }
  `}

  transition-property: background-color;

  &:before {
    content: '';
    position: absolute;
    top: 3px;
    right: 3px;
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: ${colors.watermelon};
    border: 1px solid ${colors.white};
  }

  ${addStyleIfPropTruly(
    'isEmpty',
    `
      &:before {
        display: none;
      }
  `
  )}
`;

export const ProductLink = styled(Link).attrs({
  'data-testid': PRODUCT_LINK_TEST_ID,
})`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

interface ICardStatusProps {
  notAvailable?: boolean;
  revert?: boolean;
  isHoverable?: boolean;
}

const cardWrapperHover = ({ notAvailable }: ICardStatusProps) => `
    &:before {
      opacity: ${notAvailable ? '0' : '1'};
      visibility: ${notAvailable ? 'hidden' : 'visible'};
    }
`;

export const CardWrapper = styled(Flex).attrs({
  'data-testid': PRODUCT_TEST_ID,
  px: 6,
  py: 6,
  pxXs: 2,
  pyXs: 2,
})<ICardStatusProps>`
  position: relative;

  &:active {
    background: none;
  }

  ${mediaSmUp`
    min-width: 229px;
    height: 380px;

    &:before {
      ${coverParent};
      opacity: 0;
      visibility: hidden;
      border-radius: 2px;
      box-shadow: 0 8px 16px 0 rgba(48, 56, 64, 0.04), 0 0 6px 0 rgba(48, 56, 64, 0.1);
      transition: ${Transitions.opacity};
      z-index: -1;
    }

    &:hover,
    &:focus {
      ${cardWrapperHover};
    }
    ${({ isHoverable, notAvailable }: ICardStatusProps) => isHoverable && cardWrapperHover({ notAvailable })}
  `}

  ${mediaSmDown`
    margin-bottom: 12px;
  `}
`;

export const QuantityStyled = styled(QuantityControls)<{ theme: DefaultTheme }>`
  ${mediaMobile`
    height: 42px;
    
    & {
      background-color: ${colors.paleSnowyMint};
    }
  `}
`;

export const ControlsWrapper = styled(Flex).attrs({
  column: true,
})<{ productState: ProductStates; isActive: boolean }>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  z-index: 1;
  flex-grow: 1;
  transition: ${Transitions.opacity};

  ${mediaSmUp`
    min-height: 84px;
  `}

  ${mediaMdDown`
    opacity: 1;
  `}

  ${mediaSmDown`
    max-width: ${({ productState }: { productState: ProductStates }) =>
      productState === ProductStates.toBasket ? 'max-content' : '100%'};
    margin-top: 0;
  `}
`;

export const FavoriteButtonStyled = styled(FavoriteButton)`
  opacity: ${({ isActive }) => (isActive ? '1' : '0')};

  ${mediaMdDown`
    opacity: 1;
  `}
`;

export const RemoveButtonStyled = styled(IconRemoveButton)`
  opacity: 0;

  ${mediaMdDown`
    opacity: 1;
  `}
`;

export const AddToUserSetContainer = styled(Box)`
  opacity: 0;
  z-index: 10;

  ${mediaMdDown`
    display: none;
  `}
`;

export const CommentWrapper = styled(Box).attrs({
  mt: 2,
})<{ isActive: boolean }>`
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: ${Transitions.opacity};

  ${mediaMdDown`
    opacity: 1;
  `}

  ${hideBeforeSm}
`;

export const GridCardStyled = styled(GridCard)`
  /* temporary fix */
  min-height: 270px;
  height: 316px;

  &:hover,
  &:focus {
    ${/* sc-selector */ ControlsWrapper},
    ${/* sc-selector */ FavoriteButtonStyled}, 
    ${/* sc-selector */ RemoveButtonStyled}, 
    ${/* sc-selector */ CommentWrapper},
    ${/* sc-selector */ AddToUserSetContainer} {
      opacity: 1;
    }
  }

  &:focus {
    z-index: 3;
  }

  ${mediaSmDown`
    /* temporary fix */ min-height: 240px;
    height: 240px;

    & ${ControlsWrapper} {
      opacity: 1;
      width: 100%;
      flex-grow: 1;
    }
  `}
`;

export const TopSlotContainer = styled(Flex).attrs({ fluid: true, between: true })`
  & > * {
    z-index: 2;
  }
`;

export const TopIconsGroup = styled(Grid).attrs({
  autoCol: 'auto',
  gapCol: 2.5,
})`
  grid-auto-flow: column;
`;

export const StyledLikeIcon = styled(LikeIcon)<{ $outline: boolean; $isFavoriteLocal: boolean }>`
  & > :first-child {
    display: ${({ $outline }) => !$outline && 'none'};
  }

  & > :nth-child(2) {
    fill: ${({ $isFavoriteLocal }) => ($isFavoriteLocal ? colors.primaryMain : 'none')};
    stroke: ${({ $isFavoriteLocal }) => ($isFavoriteLocal ? colors.primaryMain : colors.dark100)};
  }
`;
