import styled from 'styled-components';
import { Flex } from '@igooods/ui-kit';
import { colors } from '../../../constants/colors';

export const PromoContainer = styled.div`
  position: relative;
`;

export const PromoVrapper = styled(Flex)`
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 28px;
  max-height: 165px;
  overflow-y: scroll;
  border-radius: 12px;
  padding: 16px 20px 16px 16px;
  border: 1px solid ${colors.lightBlackRussian};
  gap: 12px;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${colors.paleBlackRussian};
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }
`;

export const PromocodeContainer = styled(Flex)`
  cursor: pointer;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PromocodeInfo = styled(Flex)`
  flex-direction: column;
  gap: 2px;
`;

export const PromocodeName = styled.div<{ active: boolean }>`
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  font-family: 'Montserrat';
  color: ${colors.blackRussian};

  ${({ active }) => active && `color:${colors.primaryAction}`};
`;

export const PromocodeDescription = styled.div<{ active: boolean }>`
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: ${colors.dark200};

  ${({ active }) => active && `color:${colors.primaryAction}; opacity: 46%`};
`;
