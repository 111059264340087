import React, { FC, memo } from 'react';
import styled, { keyframes } from 'styled-components';
import Flex from 'Components/Grid/Flex';
import { colors } from 'constants/colors';
import { mediaSmDown } from 'theme/helpers/css';
import { SEARCH_LOADING_TEST_ID } from './constants';

const loadAnim = keyframes`
  from {
    background-position: -60px 0;
  }
  to {
    background-position: 50px 0;
  }
`;

const SearchLine = styled.div`
  width: 100%;
  height: 18px;
  border-radius: 100px;
  background: linear-gradient(to right, ${colors.titanWhite} 15%, ${colors.silver} 35%, ${colors.titanWhite} 50%);
  animation: ${loadAnim} 1s infinite;

  ${mediaSmDown`
    height: 28px;
  `}
`;

const ProductInfo = styled(Flex).attrs({
  column: true,
  center: true,
  start: true,
  grow: true,
  children: (
    <>
      <SearchLine />
    </>
  ),
})``;

export const ProductPlaceholder = styled(Flex).attrs({
  between: true,
  children: (
    <>
      <ProductInfo />
    </>
  ),
})<{
  width: any;
}>`
  padding-bottom: 18px;
  ${mediaSmDown`
    padding-bottom: 12px;
  `}
  ${({ width }) => `width: ${width}%`}
`;

const ProductsContainer = styled(Flex).attrs({
  px: 4,
  pt: 1.75,
  ptXs: 0.5,
  column: true,
})``;

const SearchLoading: FC = () => {
  return (
    <Flex width="100%" column data-testid={SEARCH_LOADING_TEST_ID}>
      <ProductsContainer>
        <ProductPlaceholder width={95} />
        <ProductPlaceholder width={65} />
        <ProductPlaceholder width={75} />
        <ProductPlaceholder width={85} />
        <ProductPlaceholder width={65} />
        <ProductPlaceholder width={75} />
      </ProductsContainer>
    </Flex>
  );
};

export default memo(SearchLoading);
