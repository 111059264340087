import { useEffect, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { isBrowser } from 'utils/helpers';

const useIsMobileMediaQuery = (initialValue?: boolean) => {
  const [isMobile, setIsMobile] = useState(initialValue);
  const theme = useContext(ThemeContext);
  const mediaQueryList = isBrowser && window.matchMedia(`(max-width: ${theme.breakpoints.sm - 1}px)`);

  useEffect(() => {
    const handleMediaQueryChange = ({ matches }: MediaQueryListEvent) => setIsMobile(matches);

    if (mediaQueryList) {
      setIsMobile(mediaQueryList.matches);
      mediaQueryList.addEventListener('change', handleMediaQueryChange);

      return () => mediaQueryList.removeEventListener('change', handleMediaQueryChange);
    }
  }, [mediaQueryList]);

  return isMobile;
};

export default useIsMobileMediaQuery;
