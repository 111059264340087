import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const AttentionIcon: FC<IIcon> = props => (
  <Icon width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      xmlns="http://www.w3.org/2000/svg"
      fillRule="nonzero"
      d="M7 1C3.68 1 1 3.68 1 7s2.68 6 6 6 6-2.68 6-6-2.68-6-6-6zm-.84 2.84c0-.453.36-.813.813-.813.24 0 .454.093.6.253.16.16.254.373.254.6v3.573a.86.86 0 0 1-.854.854.817.817 0 0 1-.813-.814V3.84zm1.853 6.227c-.053.48-.44.866-.906.906-.654.067-1.2-.48-1.134-1.133.054-.467.44-.867.907-.907a1.02 1.02 0 0 1 1.133 1.134z"
    />
  </Icon>
);

export default AttentionIcon;
