import React from 'react';
import Icon, { IIcon } from './Icon';

const ChevronDownMiniIcon: React.FC<IIcon> = props => (
  <Icon width="16" height="16" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.46967 6.46967C4.76256 6.17678 5.23744 6.17678 5.53033 6.46967L8 8.93934L10.4697 6.46967C10.7626 6.17678 11.2374 6.17678 11.5303 6.46967C11.8232 6.76256 11.8232 7.23744 11.5303 7.53033L8 11.0607L4.46967 7.53033C4.17678 7.23744 4.17678 6.76256 4.46967 6.46967Z"
    />
  </Icon>
);

export default ChevronDownMiniIcon;
