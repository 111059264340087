import { Toggle } from '@igooods/ui-kit';

export interface IToggleTheme {
  bgColor: string;
  checkedBg: string;
  hoverBg: string;
  disabledBg: string;
  tapArea: number;
}

export default Toggle;
