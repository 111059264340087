import styled from 'styled-components';
import { Box, Flex } from '@igooods/ui-kit';
import { colors } from 'constants/colors';
import { Text } from 'Components/Typography';
import { FontSize } from 'theme';
import { EnumUserSetsDropdownPosition } from './';
import { MutableRefObject } from 'react';

export const DropdownContent = styled(Flex).attrs({ column: true, pt: 6, px: 5.5, pb: 3, mt: 3 })<{
  position: EnumUserSetsDropdownPosition;
  ref: MutableRefObject<HTMLDivElement | null>;
}>`
  background-color: ${colors.white};
  border-radius: 24px;
  width: 360px;
  filter: drop-shadow(0px 0px 18px rgba(27, 27, 95, 0.08)) drop-shadow(0px 20px 26px rgba(27, 27, 95, 0.04));
  overflow: hidden;
  position: relative;

  ${({ position }) => {
    switch (position) {
      case EnumUserSetsDropdownPosition.CENTER:
        return `
          left: 50%;
          transform: translateX(-50%);
        `;
      case EnumUserSetsDropdownPosition.LEFT:
        return 'left: 0;';
      case EnumUserSetsDropdownPosition.RIGHT:
        return 'right: 0;';
    }
  }}
`;

export const DropdownText = styled(Text).attrs({ size: FontSize.s })`
  line-height: 18px;
  letter-spacing: -0.2px;
  position: relative;
  &::before {
    content: '';
    display: none;
    width: calc(100% + 2px);
    height: 7px;
    border-radius: 3.5px;
    background-color: ${colors.sweetCorn};
    position: absolute;
    left: -1px;
    bottom: 1px;
    z-index: -1;
  }
`;

export const DropdownItem = styled(Flex).attrs({ middle: true, mb: 3.5 })<{ isActive: boolean }>`
  cursor: ${({ isActive }) => (isActive ? 'default' : 'pointer')};
  &:hover ${/* sc-selector */ DropdownText}::before {
    display: ${({ isActive }) => (isActive ? 'none' : 'block')};
  }
`;

export const ColorPoint = styled(Box)<{ color: string }>`
  height: 10px;
  width: 10px;
  background-color: ${({ color }) => color};
  border-radius: 5px;
`;

export const IconTogglerContainer = styled(Box)`
  background-color: ${colors.white};
  border-radius: 4px;
`;
