import React, { memo, useCallback, useEffect, useState } from 'react';
import useDidUpdate from '@rooks/use-did-update';
import { EnumSize, ModalType, ProductFavoriteStates } from 'constants/enums';
import { Size } from 'constants/types';
import { checkIsTempUser } from 'Modules/AAA/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from 'constants/colors';
import styled from 'styled-components';
import { setNotificationAction } from 'Modules/Notifications/actions';
import ModalActions from 'Modules/Modals/actions';
import ProductActions from 'Modules/Products/actions';
import { EnumFromName } from 'constants/enums';
import { IProduct } from 'Modules/Products/types';
import { useDebounce } from '../../utils/hooks';
import { mediaSmDown, mediaSmUp } from 'theme/helpers/css';
import { Box } from '@igooods/ui-kit';
import HeartIcon from '../Icon/HeartIcon';
import { FAVORITE_ICON_TEST_ID, FAVORITE_AUTH_POPUP_TEST_ID, FAVORITE_AUTH_BUTTON_TEST_ID } from './constants';

interface IProductFavoriteProps {
  modelId: number;
  isFavorite: boolean;
  size?: Size;
  className?: string;
  isShowIcon?: boolean;
  closeModal?: () => void;
  productForAnalitic: IProduct;
  from: EnumFromName;
  from_product_page?: boolean;
}

interface IHeartIcon {
  isActive?: boolean;
  isShowIcon?: boolean;
  size: Size;
  onClick?: () => void;
}

const DESKTOP_HEART_ICON_SIZE = {
  [EnumSize.xs]: 14,
  [EnumSize.sm]: 14,
  [EnumSize.md]: 24,
  [EnumSize.lg]: 36,
};

const MOBILE_HEART_ICON_SIZE = {
  [EnumSize.xs]: 14,
  [EnumSize.sm]: 14,
  [EnumSize.md]: 22,
  [EnumSize.lg]: 36,
};

export const FavoriteIcon = styled(HeartIcon).attrs<IHeartIcon>(({ isActive }) => ({
  color: isActive ? colors.watermelon : colors.silverFour,
}))<IHeartIcon>`
  position: relative;
  z-index: 2;
  cursor: pointer;
  transform: scale(1) translate(0, 0);
  transition-property: transform, fill;
  transition-duration: 100ms;
  transition-timing-function: ease-out;

  ${mediaSmUp`
    width: ${({ size }: IHeartIcon) => `${DESKTOP_HEART_ICON_SIZE[size]}px`};
    height: ${({ size }: IHeartIcon) => `${DESKTOP_HEART_ICON_SIZE[size]}px`};

    &:hover {
      fill: ${colors.watermelon};
      transform: scale(1.2);
    }

    ${({ isActive }: IHeartIcon) => isActive && 'transform: scale(1.2);'}

    &:active {
      transform: scale(1.2);
    }
  `}

  ${mediaSmDown`
    width: ${({ size }: IHeartIcon) => `${MOBILE_HEART_ICON_SIZE[size]}px`};
    height: ${({ size }: IHeartIcon) => `${MOBILE_HEART_ICON_SIZE[size]}px`};
  `}
`;

const HeartAuthPopup = styled(Box).attrs({ hideBefore: 'sm' })`
  width: 170px;
  font-size: 11px;
  line-height: 15px;
  border-radius: 2px;
  padding: 8px 12px;
  padding-right: 32px;
  cursor: pointer;
  position: absolute;
  color: #ffffff;
  background-color: #4c4c4c;
  right: -5px;
  top: 24px;
  transform: translateY(-50%);
`;

export const HeartIconWrapper = styled.div<IHeartIcon>`
  width: 25%;
  height: 50%;
  display: flex;
  justify-content: flex-end;
  z-index: 1;

  ${mediaSmUp`
    opacity: ${({ isShowIcon, isActive }: IHeartIcon) => (isActive ? 1 : isShowIcon ? 1 : 0)};

    &:hover {
      opacity: 1;
    }

    &:hover ${FavoriteIcon}:not(:active) {
      transform: scale(1.2);
    }
  `};
`;

const HeartAuthPopupWrapper = styled.div.attrs({
  'data-testid': FAVORITE_AUTH_BUTTON_TEST_ID,
})`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
`;

export const ProductFavorite: React.FC<IProductFavoriteProps> = ({
  modelId,
  isFavorite,
  size = EnumSize.md,
  className,
  isShowIcon = true,
  closeModal = () => null,
  productForAnalitic,
  from,
  from_product_page = false,
}) => {
  const dispatch = useDispatch();
  const isTempUser = useSelector(checkIsTempUser);
  const [isShowAuthPopup, setIsShowAuthPopup] = useState(false);
  const [stateFavorite, setStateFavorite] = useState(isFavorite);
  const productState = isTempUser ? ProductFavoriteStates.unlogged : ProductFavoriteStates.default;

  useEffect(() => setStateFavorite(isFavorite), [isFavorite]);

  const handleShowAuthPopup = () => setIsShowAuthPopup(true);
  const handleHideAuthPopup = () => setIsShowAuthPopup(false);
  const handleOpenAuthModal = () => {
    closeModal();
    dispatch(ModalActions.openModal(ModalType.Auth));
  };

  const debouncedFavorite = useDebounce(stateFavorite, 500);
  const handleToggleFavorites = () => setStateFavorite(!stateFavorite);

  const saveStateProductFavorites = useCallback(async () => {
    try {
      await dispatch(
        ProductActions.toggleProductToFavorites(
          {
            model_id: modelId,
            favorite: debouncedFavorite,
          },
          productForAnalitic,
          from,
          from_product_page
        )
      );
    } catch (e) {
      console.log(e);
      dispatch(setNotificationAction('Не удалось добавить товар в «избранные»'));
    }
  }, [dispatch, modelId, debouncedFavorite, productForAnalitic, from, from_product_page]);

  useDidUpdate(() => {
    saveStateProductFavorites();
  }, [debouncedFavorite]);

  return (
    <HeartIconWrapper className={className} size={size} isShowIcon={isShowIcon} isActive={stateFavorite}>
      {
        {
          [ProductFavoriteStates.default]: (
            <FavoriteIcon
              size={size}
              isActive={stateFavorite}
              onClick={handleToggleFavorites}
              data-testid={FAVORITE_ICON_TEST_ID}
            />
          ),
          [ProductFavoriteStates.unlogged]: (
            <HeartAuthPopupWrapper
              onMouseLeave={handleHideAuthPopup}
              onMouseOver={handleShowAuthPopup}
              onClick={handleOpenAuthModal}
            >
              <FavoriteIcon size={size} isActive={isShowAuthPopup} data-testid={FAVORITE_ICON_TEST_ID} />
              {isShowAuthPopup && (
                <HeartAuthPopup data-testid={FAVORITE_AUTH_POPUP_TEST_ID}>
                  Войдите, чтобы добавить в список любимых продуктов
                </HeartAuthPopup>
              )}
            </HeartAuthPopupWrapper>
          ),
        }[productState]
      }
    </HeartIconWrapper>
  );
};

export default memo(ProductFavorite);
