import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const CheckIcon: FC<IIcon> = props => (
  <Icon width="19" height="18" viewBox="0 0 19 18" fill="none" {...props}>
    <path
      d="M3.5 9L7.82 13L15.5 5"
      stroke="currentColor"
      strokeWidth="1.7"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Icon>
);

export default CheckIcon;
