import React, { useState, useEffect, useMemo } from 'react';
import { getPromocodes } from '../../../Modules/Cart/http';
import { IPromocode } from '../../../Modules/Cart/types';
import { PromoVrapper, PromoContainer } from './Styles';
import { IPromocodesProps } from './types';
import Promocode from './Promocode';
import { isBrowser } from '../../../utils/helpers';
import InfoPopover from '../../InfoPopover/InfoPopover';

const POPOVER_BODY_TEXT = 'Нажмите на название промокода, чтобы узнать условия';

const Promocodes: React.FC<IPromocodesProps> = ({ activePromo }) => {
  const [promocodes, setPromocodes] = useState<IPromocode[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const isBannerShown = Boolean(isBrowser && localStorage.getItem('promoInfo'));
  const [isShownBanner, setIsShownBanner] = useState(isBannerShown);

  const isActivePromocodeInList = useMemo(() => {
    const findedPromocode = promocodes.find(promocode => promocode.code.toLowerCase() === activePromo?.toLowerCase());
    return Boolean(findedPromocode);
  }, [activePromo, promocodes]);

  useEffect(() => {
    const fetchData = async () => {
      if (!promocodes.length || !isActivePromocodeInList) {
        const data = await getPromocodes();
        setPromocodes(data.promocodes);
      }
    };
    fetchData();
  }, [promocodes.length, activePromo, isActivePromocodeInList]);

  const handleCloseBanner = () => setIsShownBanner(true);

  if (!promocodes.length) return <></>;

  return (
    <PromoContainer>
      {!isShownBanner && isBrowser && promocodes.length && (
        <InfoPopover handleCloseBanner={handleCloseBanner} bodyText={POPOVER_BODY_TEXT} nameForStorage={'promoInfo'} />
      )}
      <PromoVrapper>
        {promocodes.map((promocode, index) => (
          <Promocode
            promocode={promocode}
            isActive={activePromo?.toLowerCase() === promocode.code.toLowerCase()}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            key={index}
          />
        ))}
      </PromoVrapper>
    </PromoContainer>
  );
};

export default Promocodes;
