import styled from 'styled-components';
import { colors } from 'constants/colors';
import { Box } from '@igooods/ui-kit';
import { addStyleIfPropTruly } from 'utils/styledComponents';

export const Container = styled.div<{ $maxHeight?: number }>`
  width: 100%;
  height: 300%;
  ${({ $maxHeight }) => $maxHeight && `max-height: ${$maxHeight};`}
  border-radius: 20px 20px 0 0;
  background: ${colors.white};
  position: relative;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 2;
`;

export const Body = styled.div<{ $hidden: boolean }>`
  user-select: none;
  position: relative;
  padding: 30px 20px 20px;
  overflow: auto;
  height: 100%;
  ${addStyleIfPropTruly('$hidden', 'overflow: hidden;')}
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 8px;
    width: 30px;
    height: 4px;
    background-color: ${colors.blackRussian};
    opacity: 0.18;
    border-radius: 100px;
    margin: 0 auto;
    z-index: 10;
    border: none;
    outline: 0;
  }
`;

export const Background = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: ${colors.blackRussian};
  opacity: 0.6;
  pointer-events: all;
`;

export const Overlay = styled(Box).attrs({ hideAfter: 'sm' })<{
  $isOpen: boolean;
  $isServer: boolean;
  $isHide: boolean;
}>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  pointer-events: none;
  z-index: 1;
  ${addStyleIfPropTruly('$isOpen', 'z-index: 11;')}
  ${addStyleIfPropTruly('$isHide', 'display: none;')}
  ${addStyleIfPropTruly(
    '$isServer',
    `display: flex;
    justify-content: flex-end;
    flex-direction: column;`
  )}
`;
