import React from 'react';
import { floatToNumberThousandSeparator } from 'utils/helpers';
import { RUB } from 'constants/constants';
import { IProduct } from 'Modules/Products/types';
import { CategoryContainer, CategoryTitle, CategoryTotal, PharmacyMock, InvoiceLink, StyledButton } from './styled';
import { EnumItemKind } from 'Containers/Profile/Orders/OrderProducts/constants';
import { NBSP } from 'constants/constants';
import InvoiceIcon from 'assets/images/document.svg';
import { EnumTheme, EnumButtonSize } from 'constants/enums';
import Text from 'Components/Typography/Text';
import { FontSize } from 'theme';

interface ICartCategory {
  id: number;
  active: boolean;
  name: string;
  totalPrice: number;
  products: IProduct[];
}

interface IProductsByCategories {
  productsByCategories: ICartCategory[];
  renderProductsList: (params: any) => void;
  inCurrentOrder?: boolean;
  inOrder?: boolean;
  orderId?: number;
  itemKind?: EnumItemKind;
  orderInvoice?: string;
}

export const PRODUCT_FILTER_SORT_CATEGORY_TEST_ID = 'PRODUCT_FILTER_SORT_CATEGORY_TEST_ID';
export const PRODUCTS_BY_CATEGORIES_CONTAINER_TEST_ID = 'PRODUCTS_BY_CATEGORIES_CONTAINER_TEST_ID';

// TODO Jira: https://igooods.atlassian.net/browse/FS-197
const ProductsByCategories: React.FC<IProductsByCategories> = ({
  productsByCategories,
  inCurrentOrder,
  inOrder,
  orderId,
  renderProductsList,
  itemKind,
  orderInvoice,
}) => (
  <div data-testid={PRODUCTS_BY_CATEGORIES_CONTAINER_TEST_ID}>
    {productsByCategories.map(({ id, totalPrice, name, products }: ICartCategory) => (
      <CategoryContainer key={id}>
        <CategoryTitle data-testid={PRODUCT_FILTER_SORT_CATEGORY_TEST_ID}>
          {name}
          {NBSP}&middot;{NBSP}
          <CategoryTotal>
            {floatToNumberThousandSeparator(totalPrice)}
            {NBSP}
            {RUB}
          </CategoryTotal>
        </CategoryTitle>
        {products[0]?.pharmacy ? (
          <PharmacyMock>
            <Text size={FontSize.s}>Товары из аптеки смотрите в{NBSP}</Text>

            <StyledButton
              use={EnumTheme.textSecondary}
              size={EnumButtonSize.normal}
              after={<img src={InvoiceIcon} alt="" />}
            >
              <InvoiceLink href={orderInvoice} newTab>
                накладной
              </InvoiceLink>
            </StyledButton>
          </PharmacyMock>
        ) : (
          renderProductsList({
            products,
            inOrder,
            inCurrentOrder,
            orderId,
            itemKind,
          })
        )}
      </CategoryContainer>
    ))}
  </div>
);

export default ProductsByCategories;
