import { IApplicationState } from 'reduxStore/types';

export const getCategoriesState = (state: IApplicationState) => state.categories;

export const getFavoritesCategoriesState = (state: IApplicationState) => state.favoritesCategories;

export const getCategoriesListState = (state: IApplicationState) => getCategoriesState(state).list;

export const getCategoriesListIsLoaded = (state: IApplicationState) => getCategoriesState(state).isLoadedCategories;

export const getCategoryProducts = (state: IApplicationState) => getCategoriesState(state).products;

export const getCurrentCategory = (state: IApplicationState) => getCategoriesState(state).currentCategory;

export const getCurrentCategoryFilters = (state: IApplicationState) => {
  const category = getCurrentCategory(state);
  if (category) {
    return category.filters;
  }

  return [];
};

export const getCurrentCategoryTitle = (state: IApplicationState) => {
  const category = getCurrentCategory(state);

  if (category) {
    return category.name;
  }

  return '';
};

export const getCurrentCategoryPriceRange = (state: IApplicationState) => {
  const category = getCurrentCategory(state);
  if (category) {
    return category.price;
  }

  return { from: 0, to: 0 };
};

export const getCategoryProductsList = (state: IApplicationState) => getCategoryProducts(state).list;

export const getCategoryProductsTotal = (state: IApplicationState) => getCategoryProducts(state).total;

export const getCategoryProductLineProducts = (state: IApplicationState) => getCategoriesState(state).productsLine;

export const getCategoryProductLineLoading = (state: IApplicationState) =>
  getCategoriesState(state).isLoadingProductLine;
