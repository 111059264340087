import React from 'react';
import { useDispatch } from 'react-redux';
import OrderActions from '../../../Modules/Cart/actions';
import { INVITE_PROMOCODE } from '../../../App';
import Toggle from '../../Toggle';
import { PromocodeContainer, PromocodeInfo, PromocodeName, PromocodeDescription } from './Styles';
import { setNotificationAction } from '../../../Modules/Notifications/actions';
import { EnumNotification } from '../../../constants/enums';
import { IPromocodeProps } from './types';
import useModalState from '../../../utils/hooks/useModalState';
import PromoInfoPopup from '../../Popups/PromoInfoPopup';

const Promocode: React.FC<IPromocodeProps> = ({ isActive, promocode, isLoading, setIsLoading }) => {
  const dispatch = useDispatch();
  const { discount, kind, code: promoName, description, categories, special_conditions } = promocode;

  const {
    isVisible: isPromocodePopupVisible,
    showModal: showPromocodePopup,
    hideModal: hidePromocodePopup,
  } = useModalState();

  const handleRemovePromocode = async () => {
    await dispatch(OrderActions.removePromocode());
    localStorage.removeItem(INVITE_PROMOCODE);
  };

  const handleAddPromocode = async () => {
    try {
      await dispatch(OrderActions.addPromocode(promoName));
      dispatch(setNotificationAction('Промокод сработал', EnumNotification.success));
    } catch (e) {
      dispatch(setNotificationAction(`${e.response.data.error}`, EnumNotification.info));
    }
  };

  const handleTogglePromocode = async () => {
    try {
      setIsLoading(true);
      if (isActive) {
        handleRemovePromocode();
      } else if (!isActive) {
        await handleRemovePromocode();
        handleAddPromocode();
      }
    } catch (error) {
      console.warn(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDescriptionText = () => {
    switch (kind) {
      case 'invite':
      case 'shipping_cost':
      case 'discount':
        return `Скидка ${discount.discount || discount.shipping_cost} ${discount.type === 'fixed' ? '₽' : '%'}`;
      case 'points':
        return `+ ${discount.points} баллов`;
    }
  };

  const promoDescription = getDescriptionText();

  return (
    <>
      <PromocodeContainer>
        <PromocodeInfo onClick={showPromocodePopup}>
          <PromocodeName active={isActive}>{promocode.code}</PromocodeName>
          <PromocodeDescription active={isActive}>{promoDescription}</PromocodeDescription>
        </PromocodeInfo>
        <Toggle checked={isActive} name="packages" onChange={handleTogglePromocode} disabled={isLoading} />
      </PromocodeContainer>
      <PromoInfoPopup
        title={promoName}
        isShow={isPromocodePopupVisible}
        onClose={hidePromocodePopup}
        description={description}
        promoConditions={special_conditions}
        categoriesArray={categories}
      />
    </>
  );
};

export default Promocode;
