import { ThemeProvider } from 'styled-components';
import { EnumCardType, EnumCardSize, EnumFromName, ProductFavoriteStates, ProductStates } from 'constants/enums';
import React, { memo } from 'react';
import { IProduct } from 'Modules/Products/types';
import { equals } from 'ramda';
import { IAnalyticsParams } from 'utils/tagManager/types';
import { ListCard } from '@igooods/ui-kit';
import { useDebounceCallback } from '../../utils/hooks';
import ProductActions from '../../Modules/Products/actions';
import { setNotificationAction } from '../../Modules/Notifications/actions';
import { useDispatch, useSelector } from 'react-redux';
import { defaultTheme } from '../../theme';
import { buttonThemeStyles } from '../ThemedButton/themes';
import { getCurrentShop } from '../../Modules/Shops/selectors';

interface IProductProps {
  product: IProduct;
  inCurrentOrder?: boolean;
  from?: EnumFromName;
  from_product_page?: boolean;
  productsSetId?: number;
  orderId?: number;
  inOrder?: boolean;
  analyticsIntersectionObserver?: IntersectionObserver;
  position?: number;
  analyticsParams?: IAnalyticsParams;
  typeCard?: EnumCardType;
  className?: string;
  state?: ProductStates;
}

const buttonTheme = buttonThemeStyles;

export const themeListCard = {
  ...defaultTheme,
  button: { ...buttonTheme.main, height: { small: 32, normal: 40, large: 50 } },
  typography: {
    ...defaultTheme.typography,
    300: { fontSize: '14px', lineHeight: '18px' },
    400: { fontSize: '16px', lineHeight: '18px' },
    500: { fontSize: '18px', lineHeight: '26px' },
    600: { fontSize: '22px', lineHeight: '26px' },
  },
  typographyMobile: {
    ...defaultTheme.typographyMobile,
    200: { fontSize: '12px', lineHeight: '16px' },
    300: { fontSize: '12px', lineHeight: '16px' },
    500: { fontSize: '14px', lineHeight: '16px' },
    600: { fontSize: '18px', lineHeight: '24px' },
  },
};

const ProductListCardShort: React.FC<IProductProps> = ({
  product,
  state = ProductStates.toBasket,
  className,
  from = EnumFromName.CATALOG,
}: IProductProps) => {
  const dispatch = useDispatch();
  const currentShop = useSelector(getCurrentShop, equals);
  const favoriteState = ProductFavoriteStates.default;

  const toggleFavorite = useDebounceCallback(async favorite => {
    try {
      await dispatch(
        ProductActions.toggleProductToFavorites(
          {
            model_id: product.model_id,
            favorite: favorite,
          },
          product,
          from,
          false
        )
      );
    } catch (e) {
      console.log(e);
      dispatch(setNotificationAction('Не удалось добавить товар в «избранные»'));
    }
  }, 500);

  return (
    <ThemeProvider theme={themeListCard}>
      <ListCard
        className={className}
        product={product}
        favoriteState={favoriteState}
        state={state}
        sizeCard={EnumCardSize.small}
        typeCard={EnumCardType.short}
        onFavoriteClick={toggleFavorite}
        fullImage={currentShop.group_en === 'vkusvill'}
        isKomus
      />
    </ThemeProvider>
  );
};

export default memo(ProductListCardShort, equals);
