import React from 'react';
import { IPromoInfoPopupProps } from './types';
import SimplePopup from '../SimplePopup';
import {
  Container,
  CategoriesListContainer,
  Category,
  PromoConditions,
  PromoDescription,
  PromoTitle,
  SquareDot,
  PopupBox,
} from './styles';

const PromoInfoPopup: React.FC<IPromoInfoPopupProps> = ({
  title,
  description,
  promoConditions,
  categoriesArray,
  isShow,
  onClose,
}) => {
  return (
    <SimplePopup show={isShow} close={onClose}>
      <PopupBox>
        <Container>
          {title && <PromoTitle>{title}</PromoTitle>}
          {description && <PromoDescription>{description}</PromoDescription>}
          {promoConditions && <PromoConditions>{promoConditions}</PromoConditions>}
          {categoriesArray && (
            <CategoriesListContainer>
              {categoriesArray.map((category, index) => (
                <Category key={index}>
                  <SquareDot />
                  {category.name}
                </Category>
              ))}
            </CategoriesListContainer>
          )}
        </Container>
      </PopupBox>
    </SimplePopup>
  );
};

export default PromoInfoPopup;
