import React, { memo } from 'react';
import { dividePriceToIntAndFract, numberToFormattedCurrencyString } from 'utils/helpers';
import { EnumProductType } from 'Modules/Products/types';
import { EnumSize, ProductStates } from 'constants/enums';
import { RUB } from 'constants/constants';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import Text, { IText } from 'Components/Typography/Text';
import Flex from 'Components/Grid/Flex';
import { FontSize, FontWeight } from 'theme';
import { toDateHRDate } from 'utils/date';
import { mediaSmDown } from 'theme/helpers/css';
import { addStyleIfPropTruly } from 'utils/styledComponents';
import { omit } from 'ramda';
import dayjs from 'dayjs';

interface IProductPriceProps {
  price: number;
  state?: ProductStates;
  old_price?: number;
  size?: EnumSize;
  className?: string;
  sale: boolean;
  sale_end_date?: string;
  type: EnumProductType;
  amount: number;
  withOldPrice?: boolean;
}

export const PRICE_INTEGER_TEST_ID = 'PRICE_INTEGER_TEST_ID';
export const PRICE_NOT_AVAILABLE_TEST_ID = 'PRICE_NOT_AVAILABLE_TEST_ID';
export const PRICE_OLD_TEST_ID = 'PRICE_OLD_TEST_ID';
export const PRICE_TEST_ID = 'PRICE_TEST_ID';
export const PRICE_FRACT_TEST_ID = 'PRICE_FRACT_TEST_ID';
export const PRICE_SALE_END_DATE_TEST_ID = 'PRICE_SALE_END_DATE_TEST_ID';

const PRICE_SIZES = {
  [EnumSize.lg]: {
    priceIntSize: FontSize.xxl,
    priceFractSize: FontSize.l,
    oldPriceSize: FontSize.m,
    saleSize: FontSize.s,
  },

  [EnumSize.md]: {
    priceIntSize: FontSize.xl,
    priceFractSize: FontSize.l,
    oldPriceSize: FontSize.s,
    saleSize: FontSize.xs,
  },

  [EnumSize.sm]: {
    priceIntSize: FontSize.m,
    priceFractSize: FontSize.s,
    oldPriceSize: FontSize.xs,
    saleSize: FontSize.xs,
  },

  [EnumSize.xs]: {
    priceIntSize: FontSize.s,
    priceFractSize: FontSize.s,
    oldPriceSize: FontSize.xs,
    saleSize: FontSize.xs,
  },
};

export const PriceReal = styled(Flex)<{ sale?: boolean }>`
  align-items: baseline;
  ${addStyleIfPropTruly('sale', `color: ${colors.watermelon};`)}
`;

export const PriceInt = styled(Text)`
  font-weight: ${({ size }: IText) =>
    size === FontSize.m || size === FontSize.s ? FontWeight.normal : FontWeight.semiBold};
`;

const PriceFract = styled(props => <PriceInt {...omit(['sale'], props)} />)<{ sale?: boolean }>`
  color: ${colors.warmGrey};
  margin-right: 6px;
  ${addStyleIfPropTruly('sale', `color: ${colors.watermelon};`)}
`;

export const PriceOld = styled(Text)`
  position: absolute;
  text-decoration: line-through;
  width: 100%;
  top: -22px;
  margin-top: 7px;
  color: ${colors.watermelon};
  ${mediaSmDown`
    top: -12px;
    margin-top: 0;
  `}
`;

const PriceEndDate = styled(Text)`
  opacity: 0.5;
  color: ${colors.watermelon};
`;

export const Container = styled.div`
  margin-bottom: 8px;
  position: relative;

  ${mediaSmDown`
    margin-bottom: 4px;
  `}
`;

function calcPrice(price: number, type: EnumProductType, amount: number) {
  const { priceInt, priceFract } = dividePriceToIntAndFract(price);

  if (type === EnumProductType.pack) {
    return { priceInt: `${amount > 0 ? '~' : ''}${priceInt}`, priceFract };
  }

  return { priceInt, priceFract };
}

export const ProductPrice: React.FC<IProductPriceProps> = ({
  price,
  state,
  old_price,
  size = EnumSize.md,
  className,
  sale,
  sale_end_date,
  type,
  amount,
  withOldPrice = false,
}) => {
  const { priceInt, priceFract } = calcPrice(price, type, amount);
  const { priceIntSize, priceFractSize, oldPriceSize, saleSize } = PRICE_SIZES[size];

  return (
    <Container className={className}>
      {state === ProductStates.notAvailable ? (
        <PriceReal data-testid={PRICE_NOT_AVAILABLE_TEST_ID}>
          <PriceInt size={priceIntSize}>—</PriceInt>
        </PriceReal>
      ) : (
        <>
          {old_price && withOldPrice && (
            <PriceOld data-testid={PRICE_OLD_TEST_ID} size={oldPriceSize}>
              {numberToFormattedCurrencyString(old_price)}
            </PriceOld>
          )}
          <PriceReal sale={sale} data-testid={PRICE_TEST_ID}>
            <PriceInt size={priceIntSize} data-testid={PRICE_INTEGER_TEST_ID}>
              {priceInt}
            </PriceInt>
            <PriceFract data-testid={PRICE_FRACT_TEST_ID} size={priceFractSize} sale={sale}>
              ,{priceFract} {RUB}
            </PriceFract>
            {sale_end_date && dayjs().isSameOrBefore(sale_end_date, 'day') && (
              <PriceEndDate data-testid={PRICE_SALE_END_DATE_TEST_ID} size={saleSize}>{`до ${toDateHRDate(
                sale_end_date
              )}`}</PriceEndDate>
            )}
          </PriceReal>
        </>
      )}
    </Container>
  );
};

export default memo(ProductPrice);
