import styled, { css, keyframes } from 'styled-components';

import Flex from 'Components/Grid/Flex';
import React from 'react';
import { colors } from 'constants/colors';
import { mediaSmDown } from 'theme/helpers/css';

const glimmer = keyframes`
  0% {
    opacity: 0.6;
  }

  50% {
    opacity: 1; 
  }

  100% {
    opacity: 0.6;
  }
`;

export const anim = css`
  animation: ${glimmer} 1400ms linear infinite;
`;

export const InputSkeleton = styled.div`
  width: 100%;
  min-height: 40px;
  background: #f5f6f7;
  border-radius: 4px;
  ${anim}
`;

export const SelectBtnSkeleton = styled.div`
  min-width: 165px;
  max-width: 165px;
  min-height: 40px;
  max-height: 40px;
  background: #f5f6f7;
  border-radius: 4px;
  align-self: center;
  position: absolute;
  right: 24px;
  ${anim}
`;

interface ICheckboxSkeleton {
  round?: boolean;
}

export const CheckboxSkeleton = styled.div<ICheckboxSkeleton>`
  display: flex;
  flex-direction: row;
  align-items: center;

  &:before {
    content: '';
    display: block;
    min-width: 16px;
    min-height: 16px;
    background: #f0f1f2;
    border-radius: ${props => (props.round ? '50%' : '4px')};
  }

  &:after {
    content: '';
    display: block;
    min-width: 60px;
    min-height: 13px;
    background: #f5f6f7;
    border-radius: 4px;
    margin-left: 10px;
  }

  &:last-child {
    margin-top: 10px;
  }

  ${anim}
`;

export const RadioGroupSkeleton = styled.div.attrs({
  children: (
    <>
      <CheckboxSkeleton round />
      <CheckboxSkeleton round />
    </>
  ),
})``;

export const TextAreaSkeleton = styled.div`
  min-height: 60px;
  background: #f5f6f7;
  width: 100%;
  border-radius: 4px;
  ${anim}
`;

const shineLines = keyframes`
   0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 700px;
  }
`;

export const loaderLineAnim = css`
  background-image: linear-gradient(90deg, ${colors.silver} 0px, ${colors.white} 80px, ${colors.silver} 160px);
  background-size: 300%;
  animation: ${shineLines} 3s infinite linear;
`;

interface ILoadingLineProps {
  background?: string;
  width?: string;
  height?: string;
  heightXS?: string;
  borderRadius?: string;
}

export const LoadingLine = styled(Flex)<ILoadingLineProps>`
  border-radius: ${({ borderRadius = '6px' }) => borderRadius};
  background: ${({ background = colors.paleGreyThree }) => background};
  max-width: ${({ width }) => width};
  min-width: 100px;
  height: ${({ height }) => height};
  min-height: 12px;

  ${mediaSmDown`
    height: ${({ heightXS }: ILoadingLineProps) => heightXS};
  `}
`;
