import React from 'react';
import Icon, { IIcon } from './Icon';
import { colors } from '../../constants/colors';

const CloseButtonIcon: React.FC<IIcon> = ({ color = colors.pineGreen, ...props }) => (
  <Icon width="15" height="14" viewBox="0 0 15 14" fill="none" {...props}>
    <path d="M13.4991 0.951172L1.50006 12.9502" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
    <path d="M11.0749 12.9502L3.92422 0.951172" stroke={color} strokeWidth="1.7" strokeLinecap="round" />
  </Icon>
);

export default CloseButtonIcon;
