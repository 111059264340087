import React, { FC } from 'react';
import { EnumButtonSize } from 'constants/enums';
import styled from 'styled-components';
import { colors } from 'constants/colors';
import { mediaSmDown } from 'theme/helpers/css';
import { FontSize } from 'theme';
import Text from 'Components/Typography/Text';
import { addStyleIfPropTruly, addXsStyleIfPropTruly } from 'utils/styledComponents';
import { shadows } from 'constants/shadows';
import Flex from 'Components/Grid/Flex';
import { Transitions } from 'theme';
import Dropdown from 'Components/Dropdown';

export const DROPDOWN_SELECT_CONTAINER_TEST_ID = 'DROPDOWN_SELECT_CONTAINER_TEST_ID';
export const DROPDOWN_SELECT_CONTENT_TEST_ID = 'DROPDOWN_SELECT_CONTENT_TEST_ID';
export const DROPDOWN_SELECT_ITEM_TEST_ID = 'DROPDOWN_SELECT_ITEM_TEST_ID';

interface IDropdownSelectContainer {
  $withMb: boolean;
  isOpen: boolean;
  $maxWidth?: string;
}

export const Container = styled.div<IDropdownSelectContainer>`
  position: relative;
  ${({ $maxWidth }) => $maxWidth && `max-with: ${$maxWidth};`}
  ${({ isOpen }) => isOpen && 'z-index: 100;'}
  width: 100%;
  ${addStyleIfPropTruly('$withMb', ` margin-bottom: 20px;`)}
  ${addXsStyleIfPropTruly('$withMb', 'margin-bottom: 8px;')}

  ${mediaSmDown`
    max-width: 100%;
  `}
`;

const getFontSizeByButtonSize = (size: EnumButtonSize): FontSize => {
  switch (size) {
    case EnumButtonSize.small:
      return FontSize.xs;
    default:
      return FontSize.m;
  }
};

const List = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  z-index: 3;
  max-height: 220px;
  top: 100%;
  border-radius: 4px;
  background-color: ${colors.white};
  box-shadow: ${shadows.boxShadow};
`;

const Item = styled(Flex).attrs({
  middle: true,
})<{ selected: boolean }>`
  font-size: 14px;
  height: 40px;
  padding: 4px 12px;
  background-color: ${colors.white};
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  transition: ${Transitions.bg};

  ${addStyleIfPropTruly('selected', 'display: none;')}

  ${mediaSmDown`
    height: 30px;
    font-size: 12px;
  `}

  &:hover {
    background-color: ${colors.paleGrey};
  }
`;

export interface IDropdownComponentProps {
  className?: string;
  classNameContent?: string;
  list: string[];
  selectedItem?: number;
  onSelectDropdownItem?: (index: number) => void;
  testId?: string;
  testIdContent?: string;
  testIdItem?: string;
  itemSize?: EnumButtonSize;
  withMb?: boolean;
  toggle: React.ReactChild | React.ReactNode;
  maxWidth?: string;
  isOpen: boolean;
  close: () => void;
  onToggle?: () => void;
}

export const DropdownComponent: FC<IDropdownComponentProps> = ({
  className,
  classNameContent,
  list,
  selectedItem,
  onSelectDropdownItem,
  testId = DROPDOWN_SELECT_CONTAINER_TEST_ID,
  testIdContent = DROPDOWN_SELECT_CONTENT_TEST_ID,
  testIdItem = DROPDOWN_SELECT_ITEM_TEST_ID,
  itemSize = EnumButtonSize.normal,
  withMb = false,
  toggle,
  maxWidth,
  isOpen,
  close,
  onToggle,
}) => {
  const itemClickHandler = (index: number) => {
    onSelectDropdownItem && onSelectDropdownItem(index);
    close();
  };

  return (
    <Container $withMb={withMb} $maxWidth={maxWidth} className={className} data-testid={testId} isOpen={isOpen}>
      <Dropdown fluid isOpen={isOpen} toggle={toggle} onToggle={onToggle} onClose={close}>
        <List className={classNameContent} data-testid={testIdContent}>
          {list.map((item, i) => (
            <Item selected={i === selectedItem} onClick={() => itemClickHandler(i)} key={item} data-testid={testIdItem}>
              <Text overflow size={getFontSizeByButtonSize(itemSize)}>
                {item}
              </Text>
            </Item>
          ))}
        </List>
      </Dropdown>
    </Container>
  );
};

export default DropdownComponent;
