export const BACKDROP_TEST_ID = 'BACKDROP_TEST_ID';
export const KEYBOARD_NOTE_TEST_ID = 'KEYBOARD_NOTE_TEST_ID';
export const SEARCH_RESULTS_TEST_ID = 'SEARCH_RESULTS_TEST_ID';
export const SEARCH_LOADING_TEST_ID = 'SEARCH_LOADING_TEST_ID';
export const PRODUCT_TEST_ID = 'PRODUCT_TEST_ID';
export const FAVORITE_ICON_TEST_ID = 'FAVORITE_ICON_TEST_ID';
export const SHOW_ALL_BUTTON_TEST_ID = 'SHOW_ALL_BUTTON_TEST_ID';
export const SEARCH_BUTTON_TEST_ID = 'SEARCH_BUTTON_TEST_ID';
export const PRODUCT_LINK_TEST_ID = 'PRODUCT_LINK_TEST_ID';
export const SEARCH_INPUT_TEST_ID = 'SEARCH_INPUT_TEST_ID';

export const TAG_HEIGHT = 46;
export const TAG_Y_MARGIN = 3.5;
export const TAGS_MAX_DISPLAY_COUNT = 6;
export const TAG_Y_PADDING = 10;
export const COEFF = 4;
