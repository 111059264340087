import React from 'react';
import Icon, { IIcon } from './Icon';

const HeartIcon: React.FC<IIcon> = props => (
  <Icon color="#E5E5E5" width="36" height="36" viewBox="0 0 36 36" {...props}>
    <path d="M5.46022 19.3392L17.9963 31.879L30.5398 19.3392C31.3198 18.5592 31.9385 17.6333 32.3606 16.6142C32.7827 15.5951 33 14.5028 33 13.3997C33 12.2967 32.7827 11.2044 32.3606 10.1853C31.9385 9.16619 31.3198 8.24021 30.5398 7.46022C29.7598 6.68024 28.8338 6.06152 27.8147 5.63939C26.7956 5.21727 25.7033 5 24.6003 5C22.0055 5 19.7439 6.875 18.9375 7.8125L18 8.75L17.0625 7.8125C15.4872 6.23725 13.6275 5 11.3997 5C10.2967 5 9.20439 5.21727 8.18529 5.63939C7.16619 6.06152 6.24021 6.68024 5.46022 7.46022C4.68024 8.24021 4.06152 9.16619 3.63939 10.1853C3.21727 11.2044 3 12.2967 3 13.3997C3 15.6275 3.88497 17.764 5.46022 19.3392Z" />
  </Icon>
);

export default HeartIcon;
