import React from 'react';
import Icon, { IIcon } from './Icon';
import { colors } from 'constants/colors';

const LikeIcon = (props: IIcon) => (
  <Icon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path
      d="M11 19C11.1581 19.4743 11.1583 19.4743 11.1585 19.4742L11.1591 19.474L11.1604 19.4736L11.1643 19.4722L11.1771 19.4679C11.1877 19.4642 11.2025 19.459 11.2214 19.4521C11.2592 19.4384 11.3131 19.4184 11.3817 19.3914C11.5188 19.3374 11.7146 19.2559 11.9565 19.1431C12.4402 18.9176 13.1099 18.5664 13.8654 18.0597C15.3757 17.0467 17.2371 15.4062 18.6321 12.8988L18.6322 12.8988L18.6374 12.889C19.6745 10.9231 20.1251 8.96951 19.9703 7.38749C19.8149 5.79786 19.0252 4.50261 17.5333 4.11337C16.4362 3.82713 15.2016 4.10203 14.0213 4.71538C12.978 5.25755 11.937 6.08367 11 7.10901C10.063 6.08367 9.022 5.25755 7.97871 4.71538C6.79846 4.10203 5.56386 3.82713 4.46673 4.11337C2.97478 4.50261 2.18514 5.79786 2.02968 7.38749C1.87496 8.96951 2.32552 10.9231 3.36261 12.889L3.3625 12.889L3.36792 12.8988C4.76292 15.4062 6.62429 17.0467 8.1346 18.0597C8.89007 18.5664 9.55983 18.9176 10.0435 19.1431C10.2855 19.2559 10.4812 19.3374 10.6183 19.3914C10.6869 19.4184 10.7409 19.4384 10.7786 19.4521C10.7975 19.459 10.8124 19.4642 10.823 19.4679L10.8357 19.4722L10.8396 19.4736L10.841 19.474L10.8415 19.4742C10.8417 19.4743 10.8419 19.4743 11 19ZM11 19L11.1581 19.4743L11 19.527L10.8419 19.4743L11 19Z"
      fill="currentColor"
      stroke={colors.dark100}
    />
    <path
      d="M4.24449 12.4175C3.27234 10.5728 2.89453 8.81822 3.02493 7.48482C3.15471 6.15784 3.7647 5.33 4.71917 5.08098C5.48835 4.8803 6.45968 5.05295 7.51758 5.60272C8.56803 6.14861 9.6536 7.04208 10.6172 8.18922L11 8.64503L11.3829 8.18922C12.3464 7.04208 13.432 6.14861 14.4824 5.60272C15.5403 5.05295 16.5117 4.8803 17.2809 5.08098C18.2353 5.33 18.8453 6.15784 18.9751 7.48482C19.1055 8.81822 18.7277 10.5728 17.7555 12.4175C16.4557 14.7516 14.7209 16.2818 13.3084 17.2292C12.602 17.703 11.9783 18.0296 11.534 18.2368C11.3119 18.3403 11.1351 18.4138 11.0156 18.4608C11.0103 18.4629 11.0051 18.4649 11 18.4669C10.9949 18.4649 10.9897 18.4629 10.9844 18.4608C10.8649 18.4138 10.6881 18.3403 10.466 18.2368C10.0217 18.0296 9.39805 17.703 8.69163 17.2292C7.27912 16.2818 5.54434 14.7516 4.24449 12.4175Z"
      fill="currentColor"
      stroke={colors.dark100}
    />
  </Icon>
);

export default LikeIcon;
