import React, { ChangeEvent, ClipboardEvent, KeyboardEvent, forwardRef, memo, useState } from 'react';
import { getTypographyFontStyle, getTypographyFontStyleMobile } from 'theme/selectors';

import { FontSize } from 'theme';
import { KeyCode } from 'constants/enums';
import Text from 'Components/Typography/Text';
import { colors } from 'constants/colors';
import { mediaSmDown } from 'theme/helpers/css';
import styled from 'styled-components';
import { addStyleIfPropTruly, addXsStyleIfPropTruly } from '../../utils/styledComponents';

const Asterisk = styled.span.attrs({ children: ' *' })`
  color: #ff3377;
`;
interface ITextarea {
  disabled?: boolean;
  text?: string;
  required?: boolean;
  placeholder?: string;
  onChange?: (value: any) => void;
  onBlur?: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLDivElement>) => void;
  onFocus?: () => void;
  maxLength?: number;
  className?: string;
  testIdTextarea?: string;
  errorMsg?: string;
  withMb?: boolean;
}

export type TextareaRef = HTMLDivElement;

interface ITextareaBlock {
  className?: string;
  disabled?: boolean;
  withMb?: boolean;
}
const Container = styled.div<ITextareaBlock>`
  border-radius: 4px;
  background-color: ${colors.paleGrey};
  transition: background-color 300ms ease-out;
  position: relative;
  ${props =>
    props.disabled &&
    `
  cursor: default !important;
  background-color: #f0f1f2 !important;

  &:hover {
    background: #f0f1f2 !important;
  }
  `}

  &:hover {
    cursor: pointer;
    background-color: ${colors.silverFive};
  }
  ${addStyleIfPropTruly('withMb', 'margin-bottom: 20px;')}
  ${addXsStyleIfPropTruly('withMb', 'margin-bottom: 8px;')}
`;

export const ErrorMessage = styled(Text).attrs({
  color: colors.reddishPink,
})`
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: translateY(calc(100% + 4px));
  left: 0;
  text-align: left;
`;

const Placeholder = styled(Text)<ITextareaBlock>`
  color: ${colors.warmGrey};
  position: absolute;
  top: 11px;
  left: 12px;
  right: 12px;
  z-index: 1;
  display: block;
  ${props =>
    props.disabled &&
    `
  color: #cccccc !important;
  background: #f0f1f2 !important;
`}
`;

const Content = styled.div<ITextareaBlock>`
  ${props =>
    props.disabled &&
    `
  color: #cccccc !important;
  user-select: none;
  pointer-events: none;
`}
  width: 100%;
  user-select: text;
  padding: 10px 12px 12px;
  outline: none;
  z-index: 2;
  position: relative;
  transition: background-color 300ms ease-out;
  border-radius: 4px;
  white-space: pre-line;

  &:focus {
    background-color: ${colors.white};
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05), 0 2px 6px 0 rgba(0, 0, 0, 0.05), 0 0 4px 0 rgba(0, 0, 0, 0.05);

    & + ${Placeholder} {
      @media not all and (min-resolution: 0.001dpcm) {
        display: none;
      }
    }
  }

  word-break: break-word;
  text-align: left;
  ${getTypographyFontStyle(FontSize.s)}
  ${mediaSmDown`
    ${getTypographyFontStyleMobile(FontSize.s)};
  `}
  
  &:not(:empty) + ${Placeholder} {
    display: none;
  }
`;

const Textarea = forwardRef<TextareaRef, ITextarea>((props, ref) => {
  const {
    placeholder,
    text,
    onChange,
    className,
    onKeyDown = () => null,
    maxLength,
    onBlur,
    onFocus,
    required,
    disabled,
    testIdTextarea,
    errorMsg,
    withMb,
  } = props;

  const [lengthErr, setLengthErr] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLDivElement>) => {
    const { textContent } = e.currentTarget;
    if (onChange) {
      onChange(textContent);
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    const { textContent } = e.currentTarget;
    if (onKeyDown) onKeyDown(e);

    if (textContent && maxLength && e.keyCode !== KeyCode.Backspace && textContent.length >= maxLength) {
      e.preventDefault();
      setLengthErr(true);
    } else {
      setLengthErr(false);
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    let pastedText = e.clipboardData.getData('text/plain');

    if (maxLength && pastedText.length > maxLength) {
      pastedText = pastedText.substring(0, maxLength);
      setLengthErr(true);
    } else {
      setLengthErr(false);
    }

    document.execCommand('insertHTML', false, pastedText);
  };

  return (
    <Container disabled={disabled} withMb={withMb}>
      <Content
        ref={ref}
        data-testid={testIdTextarea}
        className={className}
        contentEditable
        suppressContentEditableWarning
        aria-multiline="true"
        onInput={handleChange}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
      >
        {text}
      </Content>
      <Placeholder disabled={disabled}>
        {placeholder}
        {required && <Asterisk />}
      </Placeholder>
      {!errorMsg && lengthErr && (
        <ErrorMessage size={FontSize.xs}>Максимальное количество символов {maxLength}</ErrorMessage>
      )}
      {errorMsg && <ErrorMessage size={FontSize.xs}>{errorMsg}</ErrorMessage>}
    </Container>
  );
});

Textarea.displayName = 'Textarea';

export default memo(Textarea);
