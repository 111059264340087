import React from 'react';
import Icon, { IIcon } from './Icon';
import { colors } from 'constants/colors';

const AddToUserSetIcon: React.FC<IIcon> = ({ color = colors.dark300, ...props }) => (
  <Icon width="22" height="22" viewBox="0 0 22 22" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.743 12.1715C14.2834 12.0758 13.9885 11.6256 14.0842 11.166L14.5001 9.16986H12.4003C11.9309 9.16986 11.5503 8.7893 11.5503 8.31986C11.5503 7.85042 11.9309 7.46986 12.4003 7.46986H14.8543L15.3426 5.12603C15.4383 4.66645 15.8885 4.37151 16.3481 4.46726C16.8076 4.563 17.1026 5.01318 17.0068 5.47275L16.5908 7.46986H18.692C19.1614 7.46986 19.542 7.85042 19.542 8.31986C19.542 8.7893 19.1614 9.16986 18.692 9.16986H16.2366L15.7485 11.5128C15.6528 11.9723 15.2026 12.2673 14.743 12.1715ZM4.90029 4.48939C4.43085 4.48939 4.05029 4.86995 4.05029 5.33939C4.05029 5.80883 4.43085 6.18939 4.90029 6.18939H9.40029C9.86973 6.18939 10.2503 5.80883 10.2503 5.33939C10.2503 4.86995 9.86973 4.48939 9.40029 4.48939H4.90029ZM3.05029 10.8394C3.05029 10.3699 3.43085 9.98939 3.90029 9.98939H8.40029C8.86973 9.98939 9.25029 10.3699 9.25029 10.8394C9.25029 11.3088 8.86973 11.6894 8.40029 11.6894H3.90029C3.43085 11.6894 3.05029 11.3088 3.05029 10.8394ZM2.90029 15.4894C2.43085 15.4894 2.05029 15.8699 2.05029 16.3394C2.05029 16.8088 2.43085 17.1894 2.90029 17.1894H17.4003C17.8697 17.1894 18.2503 16.8088 18.2503 16.3394C18.2503 15.8699 17.8697 15.4894 17.4003 15.4894H2.90029Z"
      fill={color}
    />
  </Icon>
);

export default AddToUserSetIcon;
