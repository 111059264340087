import React from 'react';
import AddToUserSetIcon from 'Components/Icon/AddToUserSetIcon';
import { IconTogglerContainer } from './styled';

const IconToggler: React.FC = () => {
  return (
    <IconTogglerContainer>
      <AddToUserSetIcon />
    </IconTogglerContainer>
  );
};

export default IconToggler;
