import React from 'react';
import styled from 'styled-components';
import { Flex } from '@igooods/ui-kit';

const WeightContainer = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  gap: 6px;
  margin: 14px 16px 14px 12px;

  h2 {
    font-weight: 600 !important;
    font-size: 16px !important;
    padding-bottom: 5px !important;
  }
`;

const WeightGroup = styled.span`
  padding-right: 30px;
`;

const ZeroWeight = styled.span`
  padding-left: 10px;
`;

const AditionalWeightContent = () => {
  return (
    <WeightContainer>
      <h2>Доплата за вес:</h2>
      <div>
        <WeightGroup>0 - 30кг</WeightGroup>
        <ZeroWeight>0 ₽</ZeroWeight>
      </div>
      <div>
        <WeightGroup>31 - 40кг</WeightGroup>
        +50 ₽
      </div>
      <div>
        <WeightGroup>41 - 60кг</WeightGroup>
        +150 ₽
      </div>
      <div>
        <WeightGroup>61 - 80кг</WeightGroup>
        +250 ₽
      </div>
    </WeightContainer>
  );
};

export default AditionalWeightContent;
