import React from 'react';
import CenteredClosableModal from 'Components/Modal/CenteredClosableModal';
import { IClosableModal } from 'Components/Modal/ClosableModal';
import ModalCloseButton from './CloseButton';

export const MODAL_CLOSE_BTN_TEST_ID = 'CLOSE_BTN_TEST_ID';

const CenteredModalWithCustomClose: React.FC<IClosableModal> = props => {
  return <CenteredClosableModal {...props} headerOnMobileHeight={61} customCloseBtn={ModalCloseButton} />;
};

export default CenteredModalWithCustomClose;
