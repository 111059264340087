import React, { FC } from 'react';
import Icon, { IIcon } from './Icon';

const Comment: FC<IIcon> = props => (
  <Icon color="#004B46" width="12" height="12" viewBox="0 0 12 12" {...props}>
    {/* eslint-disable-next-line max-len */}
    <path d="M10 0C11.1046 0 12 0.89543 12 2V7.0053C12 8.0249 11.1734 8.85145 10.1538 8.85145C9.89895 8.85145 9.69231 9.05809 9.69231 9.31299V10.9936C9.69231 11.4068 9.21957 11.6417 8.89028 11.3921L5.53846 8.85145H2C0.895429 8.85145 0 7.95602 0 6.85145V2C0 0.895431 0.895431 0 2 0H10Z" />
  </Icon>
);

export default Comment;
