import { Flex } from '@igooods/ui-kit';
import BottomSheetComponent from 'Components/BottomSheet';
import CenteredModalWithCustomClose from 'Components/Modal/CenteredModalWithCustomClose';
import { colors } from 'constants/colors';
import React from 'react';
import styled from 'styled-components';
import useIsMobileMediaQuery from 'utils/hooks/useIsMobileMediaQuery';

const ContentWrapper = styled(Flex)`
  width: auto;
  background-color: ${colors.white};
  border-radius: 20px;
  padding: 6px 1px 6px 0;
`;

interface ISimplePopupProps {
  show: boolean;
  close: () => void;
}

const SimplePopup: React.FC<ISimplePopupProps> = ({ show, close, children }) => {
  const isMobile = useIsMobileMediaQuery();
  return isMobile ? (
    <BottomSheetComponent isOpen={show} close={close} snapPoint={0} snapPointToTop={70}>
      <ContentWrapper>{children}</ContentWrapper>
    </BottomSheetComponent>
  ) : (
    <CenteredModalWithCustomClose show={show} closeModal={close}>
      <ContentWrapper>{children}</ContentWrapper>
    </CenteredModalWithCustomClose>
  );
};

export default SimplePopup;
